import { useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import MUTATION_UPDATE_CART_ITEM from '../../../aem-core-components/queries/mutation_update_cart_item.graphql';
import CART_DETAILS_QUERY from '../../../aem-core-components/queries/query_cart_details.graphql';
import { useCartState } from '../../../contexts/cart';
import useAnalytics from '../../../hooks/useAnalytics';
import useCartOptions from '../../../hooks/useCartOptions';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import { useChronosAvailability } from '../../../hooks/useChronosAvailability';
import useProduct from '../../../hooks/useProduct';
import useUserData from '../../../hooks/useUserData';
import useCartEstimate from '../../App/hooks/useCartEstimate';
import useCheckoutNavigate from '../../App/hooks/useCheckoutNavigate';
import { useFilterState } from '../../cap';
import { useAtp } from '../../cap/hooks/useATP';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { useAwaitQuery } from '../../../aem-core-components/utils/hooks';
import { useCapUtils } from '../../cap/hooks/useCapUtils';
import { getCartDetails } from '../../../aem-core-components/actions/cart';
import { getDateDiffInHrs } from '../../cap/utils/atputils';
import {
    checkIsEditQuoteFlow,
    generateSKU,
    getRentalDuration,
    redirectToCheckoutOrQuotePage
} from '../../global/utils/commonUtils';
import { isValidString, logError } from '../../global/utils/logger';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { AUTHORITY_TYPE, EMPLOYEE } from '../../global/constants';
import { TILE_STATES } from '../../global/modules/productTile/constants';
import {
    SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
    SET_CHECKOUT_ERROR_DETAILS,
    SET_CURRENT_OFFSET_VALUE,
    SET_IS_CART_LOADING,
    SET_RECOMPUTE_ITEM_AVAILABILITY,
    SET_SOURCES_LOADING_FLAG,
    SET_TIMEZONE_ID,
    SET_VIEW_CART_FIELDS
} from '../../../aem-core-components/actions/constants';
import { VIEW_CART } from '../../../constants/cartConstants';

/**
 * @class useCartOperations - is a custom hook that provides various utility functions to handle cart operations .
 * @param {item} props.item = cart item on which operation has to be performed @default {}
 * @returns {Object} An object containing various utility functions.
 */
export const useCartOperations = props => {
    const { item } = props || {};
    const cartIntl = useIntl();
    const { product = {} } = item || {};
    const [, { removeItem }] = useProduct({ item });
    const { handleATPCart, getItemAvailabilityATP } = useAtp();
    const [updateCartItemMutation] = useMutation(MUTATION_UPDATE_CART_ITEM);
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);
    const [, { dispatch, updateCartFromMinicart, removeUnavailableItems }] = useCartOptions({
        updateCartItemMutation,
        cartDetailsQuery,
        handleATPCart
    });
    const [{ cart, cartId, isCreditNewAddress, isSourcesLoading, userAccount, currentOffset }] =
        useCartState();
    const { sendEventsForEcommerceRemove, sendEventsForEcommerceAdd, sendEventsForEcommerceAction } =
        useAnalyticsContext();
    const [{ payloadEcommerceActionAnalytics }] = useAnalytics();
    const [{ startDate, endDate, viewCart, selectedStoreDetails, projectDetails }, filterDispatch] = useFilterState();
    const [{ userProfile }, { dispatch: userDispatch }] = useUserContext();
    const [{ updateFourHourRentals }] = useUserData();
    const { updateBsrPricingPCs } = useCapUtils();
    const { isSelectedLocationEmpty, isRentalDetailsAvailable } = useCheckLocationEmpty();
    const [{ getEstimates }] = useCartEstimate();
    const displayQuoteId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID) || '';
    const isEditQuoteFlow = checkIsEditQuoteFlow();
    const {
        getMergedSourcesATPStores,
        getAvailableProducts,
        getItemAvailabilityATPChronos,
        getStoresWithUnvailableProducts
    } = useChronosAvailability();
    const { getChronosStores, computeChronosStoreWithAVS, validateDateSlots, validateTimezoneAPI } =
        useCheckoutNavigate();
    const [isQuantityUpdate, setIsQuantityUpdate] = useState(false);
    const [sortedChronosPcs, setSortedChronosPcs] = useState(null);
    const [isCheckoutDetailsValidating, setIsCheckoutDetailsValidating] = useState(false);
    const [avsAndStoreData, setAvsAndStoreData] = useState({});
    const [zipUsedForTimezone, setZipUsedForTimezone] = useState('');
    const { rollbackToCi } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const isAtpDisabled = rollbackToCi || false;
    const { rentallabels = '{}' } = ENV_CONFIG.CAP_HEADER || {};
    const userType = useCheckUser();
    const authorityType = useCheckAuthorityType();
    const parsedRentalLabels = JSON.parse(rentallabels);
    const emptyRentalDetailsLabel =
        parsedRentalLabels?.['empty-rental-details'] ||
        cartIntl.formatMessage({ id: 'cart:product-tile-rental-details' });
    const emptyRentalLocationLabel =
        parsedRentalLabels?.['empty-rental-location'] ||
        cartIntl.formatMessage({ id: 'cart:product-tile-rental-location' });
    const emptyRentalDatesLabel =
        parsedRentalLabels?.['empty-rental-dates'] || cartIntl.formatMessage({ id: 'cart:product-tile-rental-dates' });
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');

    const handleRemoveItem = async itemToRemove => {
        try {
            sendEventsForEcommerceRemove(
                VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                getProductPayload(itemToRemove?.quantity)
            );
        } catch (error) {
            logError(error, false, 'handleRemoveItem');
        }
        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
        await removeItem(itemToRemove?.uid);
        updateFourHourRentals(itemToRemove?.product?.sku, false);
        dispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
        handleRemoveMergeCartError(itemToRemove);
    };

    const handleRemoveMergeCartError = item => {
        if (userProfile?.skip_qty[item?.product?.sku]) {
            const skuQtyCopy = { ...userProfile?.skip_qty };
            delete skuQtyCopy[item?.product?.sku];
            userDispatch({ type: 'updateSkipQtyObject', skipQtyObj: skuQtyCopy });
        }
    };

    const getProductPayload = quantity => {
        return [
            {
                id: item?.product?.sku,
                name: item?.product?.name,
                price: parseFloat(item?.prices?.row_total?.value)?.toFixed(2),
                categories: item?.product?.category_path || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                category:
                    item?.product?.productcategoryname ||
                    item?.product?.parent_category_name ||
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                subcategory: item?.product?.category_name || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                dimension38: getRentalDuration(startDate, endDate) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                dimension31: item?.prices?.row_total?.value,
                dimension32: item?.prices?.row_total?.value * 7,
                dimension33: item?.prices?.row_total?.value * 31,
                sku: item?.product?.sku,
                dimension41: -1,
                list: VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                position: parseInt(item?.product?.position) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                quantity: quantity
            }
        ];
    };

    const handleCartQuantityUpdation = async (newVal, item) => {
        setIsQuantityUpdate(true);
        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: true });
        await updateCartFromMinicart([{ cart_item_uid: item?.uid, quantity: newVal }]);
        dispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
        if (newVal - item?.quantity < 0) {
            try {
                sendEventsForEcommerceRemove(
                    VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                    getProductPayload(Math.abs(newVal - item?.quantity))
                );
            } catch (error) {
                logError(error, false, 'handleOnChange');
            }
        } else {
            try {
                sendEventsForEcommerceAdd(
                    VARIABLE_CONFIG.ECOMMERCE_PAGE.MINICART,
                    getProductPayload(newVal - item?.quantity)
                );
            } catch (error) {
                logError(error, false, 'handleOnChange');
            }
        }
        dispatch({ type: SET_IS_CART_LOADING, isCartLoading: false });
    };

    const initialiseSavedCart = () => {
        getCartDetails({
            cartDetailsQuery,
            dispatch,
            cartId,
            filterDispatch,
            handleATPCart,
            isRentalDetailsAvailable: isRentalDetailsAvailable()
        });
    };

    const renderEmptyRentalLabels = () => {
        if (isValidString(isAtpDisabled) && isSelectedLocationEmpty()) {
            return cartIntl.formatMessage({ id: 'cart:enter-location-for-price' });
        } else if (isSelectedLocationEmpty() && (!startDate || !endDate)) {
            return emptyRentalDetailsLabel;
        } else if (isSelectedLocationEmpty()) {
            return emptyRentalLocationLabel;
        } else if (!startDate || !endDate) {
            return emptyRentalDatesLabel;
        }
    };

    const handleRedirection = () => {
        window.location.href = product?.product_page_url;
    };

    const recomputeEstimates = async (pcVal, pcLat, pcLong, cartItems = cart?.items, signal) => {
        // not calling estimates if dates are missing
        if (pcVal && startDate && endDate && cartItems?.length > 0) {
            const cartEstimates = await getEstimates('', pcVal, pcLat, pcLong, false, cartItems, signal);
            return cartEstimates;
        }
    };

    /**
     * This function is for the scenario when user lands on checkout page after all the checks are performed.
     * This function will fetch cart items from storage and call estimates
     */
    const renderCheckoutWithoutValidations = async () => {
        try {
            let cartEstimates = null;
            const cartItems = getItemStatusFromStorageOrATP(cart?.items);
            const conditionalItems = cartItems?.conditionalItems || [];
            const unavailableItems = cartItems?.unavailableItems || [];
            if (viewCart?.pc && cartItems?.availableItems?.length) {
                // estimates call
                cartEstimates = await recomputeEstimates(
                    viewCart?.pc,
                    viewCart?.pcLat,
                    viewCart?.pcLong,
                    cartItems?.availableItems
                );
            }
            const availableItems = cartEstimates?.availableCartItems || cartItems?.availableItems || [];
            dispatch({
                type: SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
                availableCartItems: availableItems,
                unavailableCartItems: [...unavailableItems, ...conditionalItems]
            });
        } catch (error) {
            logError(error, false, 'renderCheckoutWithUserDetails');
        }
    };

    /**
     * This function is for the scenario when user lands on checkout page without the valid checks.
     * This function will fetch cart items and will validate the user details api
     * @returns isValid
     */
    const renderCheckoutWithValidations = async () => {
        try {
            const { isValid } = await validateCartDetails({ isCheckout: true });
            return isValid;
        } catch (error) {
            logError(error, false, 'renderCheckoutWithoutUserDetails');
        }
    };

    const fetchCartForCheckout = async () => {
        await updateBsrPricingPCs();
        const estimatesResponse = await getCartItems();
        const unavailableItems = estimatesResponse?.unavailableCartItems;
        // removing previous unavailable items from cart for override pc case
        if (overridePC?.pc && unavailableItems?.length > 0) {
            await removeUnavailableItems(unavailableItems, false);
        }
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.DISCARD_OLD_CART);
    };

    /**
     * This function will fetch cart items availability
     * @returns available, conditional and unavailable items
     */
    const renderCartItems = async () => {
        // resetting checkout errors
        dispatch({ type: SET_CHECKOUT_ERROR_DETAILS });
        await updateBsrPricingPCs();
        const cartDetails = await getCartItemsWithAvailability();
        const conditionalItems = cartDetails?.conditionalItems || [];
        const unavailableItems = cartDetails?.unavailableItems || [];
        const availableItems = cartDetails?.availableItems || [];
        dispatch({
            type: SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
            availableCartItems: availableItems,
            unavailableCartItems: [...unavailableItems, ...conditionalItems]
        });
        return {
            availableItems,
            conditionalItems,
            unavailableItems
        };
    };

    const getCartItemsWithAvailability = async () => {
        const [cartItemsResponse, offsetResponse] = await Promise.allSettled([getCartItems(), fetchTimezone()]);
        const estimatesResponse = cartItemsResponse?.value || {};
        const offset = offsetResponse?.value || '';
        if (estimatesResponse?.items?.length === 0) {
            return {};
        }
        let items = estimatesResponse?.items;

        const discardOldCart =
            JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.DISCARD_OLD_CART)) || false;
        const unavailableItems = estimatesResponse?.unavailableCartItems;
        // removing previous unavailable items from cart for override pc case
        if (discardOldCart && overridePC?.pc && unavailableItems?.length > 0) {
            await removeUnavailableItems(unavailableItems, false);
            items = estimatesResponse?.availableCartItems;
        }
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.DISCARD_OLD_CART);

        if (startDate && endDate) {
            return handleDateRange(items, offset);
        } else {
            setIsQuantityUpdate(false);
            return handleNoDateRange(items);
        }
    };

    const getCartItems = async () => {
        return (
            cart ||
            (await getCartDetails({
                cartDetailsQuery,
                dispatch,
                cartId,
                filterDispatch,
                handleATPCart,
                isRentalDetailsAvailable: isRentalDetailsAvailable()
            }))
        );
    };

    /** this functions accepts current address/jobsite zip,
     *  to decide if the timezone data present in context is for the same zip or not.
     *  Zip is used to decide timezone as there are 2 or more cities in US with different timezone.
     */
    const fetchTimezone = async (zip = viewCart?.jobSiteZip || projectDetails?.selectedProjectZip) => {
        if (zip === zipUsedForTimezone) {
            return currentOffset;
        }
        const { timeZoneID, offset } = await validateTimezoneAPI();
        if (!timeZoneID || !offset) {
            // resetting if data is not present
            dispatch({ type: SET_TIMEZONE_ID, timeZoneID: timeZoneID || '' });
            dispatch({ type: SET_CURRENT_OFFSET_VALUE, currentOffset: offset || '' });
        }
        setZipUsedForTimezone(zip);
        return offset || '';
    };

    const handleDateRange = async (items = [], offset = '') => {
        let cartItems;
        if (isWithin72Hours()) {
            cartItems = getItemStatusFromStorageOrATP(items);
            saveCartItemsInStorage(cartItems);
        } else {
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS);
            cartItems = getItemAvailabilityATP(items);
        }
        if (!isQuantityUpdate) {
            if (cartItems?.availableItems?.length && !overridePC?.pc) {
                handlePickupChronosStores(cartItems, offset);
            } else {
                setSortedChronosPcs(null);
            }
        }
        setIsQuantityUpdate(false);
        return cartItems;
    };

    /**
     * This function will get the item availability status from storage,
     * if status is not present in storage, will fetch it from atp
     * @param {*} items
     * @returns available, conditional and unavailable items
     */
    const getItemStatusFromStorageOrATP = items => {
        const cartItemsFromStorage = JSON.parse(
            sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS) || '{}'
        );
        const availableItems = [];
        const conditionalItems = [];
        const unavailableItems = [];
        items?.map(item => {
            const catsku = item?.product?.sku;
            let tileStatus = cartItemsFromStorage[catsku];
            if (!tileStatus) {
                // if status is not in storage, will calculate it from atp
                tileStatus = handleATPCart({
                    catsku,
                    inventoryDataObj: item?.product?.ec_pc_inventory
                });
            }
            if (tileStatus === TILE_STATES.AVAILABLE || tileStatus === TILE_STATES.AVAILABLE_WITH_WARNING) {
                availableItems.push(item);
            } else if (tileStatus === TILE_STATES.UNAVAILABLE) {
                unavailableItems.push(item);
            } else {
                conditionalItems.push({ ...item, status: tileStatus });
            }
        });
        return { availableItems, conditionalItems, unavailableItems };
    };

    /**
     * This function calls sources with available items and
     * and calls another sources with available+conditional items.
     * It then selects the stores from 1st call and decided item
     * availability from 2nd sources call.
     * @param {*} cartItems
     */
    const handlePickupChronosStores = async (cartItems, offset) => {
        try {
            dispatch({
                type: SET_SOURCES_LOADING_FLAG,
                isSourcesLoading: true
            });
            const { data: stores } = await getChronosStores(offset, {}, cartItems?.availableItems);
            const sortedAvailableStores = getMergedSourcesATPStores(stores?.data, stores?.data);
            /**
             *  will call the 2nd sources even if the 1st sources suggested the selected store
             *  as the 1st sources will not have data for conditional items.
             *  This will be called only for pickup within 72hrs as for other cases we do not
             *  show discrepency drawer, so conditional items data is not required.
             */
            if (
                cartItems?.conditionalItems?.length > 0 &&
                sortedAvailableStores?.length > 0 &&
                viewCart?.isInStorePickup &&
                isWithin72Hours()
            ) {
                const availableItems = cartItems?.availableItems || [];
                const conditionalItems = cartItems?.conditionalItems || [];
                const { data: availableCondtionalStores } = await getChronosStores(offset, {}, [
                    ...availableItems,
                    ...conditionalItems
                ]);
                const mergedStores = mergeAvailableAndConditionalStores(
                    availableCondtionalStores?.data,
                    sortedAvailableStores
                );
                setSortedChronosPcs(mergedStores);
            } else {
                setSortedChronosPcs(sortedAvailableStores);
            }
            dispatch({
                type: SET_SOURCES_LOADING_FLAG,
                isSourcesLoading: false
            });
        } catch (error) {
            logError(error, false, 'handlePickupChronosStores');
        }
    };

    const mergeAvailableAndConditionalStores = (availableCondtionalStores, availableItemsStores) => {
        if (!availableCondtionalStores?.length) {
            return availableItemsStores;
        }
        const stores = [];
        availableCondtionalStores?.forEach(conditionalStore => {
            const availableStore = availableItemsStores?.find(item => item?.pc === conditionalStore?.pc);
            if (availableStore) {
                stores?.push({ ...availableStore, items: conditionalStore?.items });
            }
        });
        return stores;
    };

    const showDiscrepencyStoreSelector = availableItems => {
        if (viewCart?.isInStorePickup && sortedChronosPcs?.length > 0) {
            if (sortedChronosPcs[0]?.pc !== selectedStoreDetails?.pc) {
                return true;
            } else {
                // this is to check when the chronos suggested the selected item but
                // some available item may be unavailable from chronos
                const isItemMissing = availableItems?.find(item => isItemMissingInStore(sortedChronosPcs[0], item));
                return !!isItemMissing;
            }
        }
        return false;
    };

    const isItemMissingInStore = (store, item) => {
        const matchedItem = store?.items?.find(
            chronosItem =>
                generateSKU(chronosItem?.equipmentCategory, chronosItem?.equipmentClass) === item?.product?.sku
        );
        // sometimes available quantity is negative from chronos
        return !matchedItem?.availableQuantity || matchedItem?.availableQuantity <= 0;
    };

    const updateItemsFromSelectedChronosStore = ({ chronosStores = sortedChronosPcs, selectedChronosStore }) => {
        try {
            // getting cart items status from atp
            localStorage.setItem(
                STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS,
                JSON.stringify(selectedChronosStore)
            );
            const cartItemsWithATP = getItemAvailabilityATP(cart?.items);
            if (cartItemsWithATP?.availableItems?.length) {
                const chronosAvailabilityStatus = getAvailableProducts(chronosStores, selectedChronosStore);
                // deciding the item availabilty based on atp + chronos
                const {
                    availableItems = [],
                    conditionalItems = [],
                    unavailableItems = []
                } = getItemAvailabilityATPChronos(cart?.items, cartItemsWithATP, chronosAvailabilityStatus);
                saveCartItemsInStorage({ availableItems, conditionalItems, unavailableItems });
                dispatch({
                    type: SET_CART_AVAILABLE_UNVAILABLE_ITEMS,
                    availableCartItems: availableItems,
                    unavailableCartItems: [...unavailableItems, ...conditionalItems]
                });
                return { availableItems, conditionalItems, unavailableItems };
            } else {
                return cartItemsWithATP;
            }
        } catch (error) {
            logError(error, false, 'updateItemsFromSelectedChronosStore');
            return {};
        }
    };

    const saveCartItemsInStorage = ({ availableItems, conditionalItems, unavailableItems }) => {
        const cartItems = {};
        availableItems?.forEach(item => {
            cartItems[item?.product?.sku] = TILE_STATES.AVAILABLE;
        });
        unavailableItems?.forEach(item => {
            cartItems[item?.product?.sku] = TILE_STATES.UNAVAILABLE;
        });
        conditionalItems?.forEach(item => {
            cartItems[item?.product?.sku] = item?.status;
        });
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS, JSON.stringify(cartItems));
    };

    const handleNoDateRange = (items = []) => {
        return getItemAvailabilityATP(items);
    };

    const isWithin72Hours = (date = startDate) => {
        return getDateDiffInHrs(date, moment().format('YYYY-MM-DDTHH:mm:ss')) < 72;
    };

    /**
     * This function adds the name of the products by fetching it from cartItems
     * @param {*} cartItems = To fetch products names
     * @param {*} storesWithUnAvailableProducts = To add product names into the object
     */
    const addItemName = (cartItems, storesWithUnAvailableProducts) => {
        const itemMap = {};
        cartItems?.forEach(item => {
            // creating itemMap lookup to store product name based on its sku
            itemMap[item?.product?.sku] = item?.product?.name;
        });

        storesWithUnAvailableProducts?.forEach(item => {
            item?.unavailableProducts?.forEach(product => {
                if (product) {
                    // adding the product name by fetching from the itemMap lookup
                    product.name = itemMap[product?.catclass];
                }
            });
        });
    };

    /**
     * This function accept cartItems array for fetching product name from catclass
     * It returns the selected store and the rest chronos stores as suggested stores
     * along with the items unavailable at each store.
     * @param {*} unavailableItemsArr
     * @param {*} cartItems
     * @returns
     */
    const getSelectedAndSuggestedStores = (unavailableItemsArr, cartItems) => {
        try {
            const storesWithUnavailableProducts = getStoresWithUnvailableProducts(sortedChronosPcs);
            addItemName(cartItems, storesWithUnavailableProducts);
            const unavailableItems =
                unavailableItemsArr?.map(item => ({
                    catclass: item?.product?.sku,
                    name: item?.product?.name
                })) || [];

            //  adding all the unavailable cart items to the stores existing unavailable products
            storesWithUnavailableProducts?.forEach(items => {
                items?.unavailableProducts?.push(...unavailableItems);
            });
            const index = storesWithUnavailableProducts?.findIndex(
                product => selectedStoreDetails?.pc === product?.store?.pc
            );

            if (index === -1) {
                /**
                 *  if the selected store is not in the sources call
                 *  then, adding all the cart items as unavailable for
                 *  the selected store and returning the chronos stores
                 *  as the suggested stores.
                 */
                return {
                    selectedStore: {
                        store: selectedStoreDetails,
                        unavailableProducts: [...cartItems?.map(items => items?.product)]
                    },
                    suggestedStores: storesWithUnavailableProducts
                };
            } else {
                /**
                 *  if the selected store is in the sources call then,
                 *  sending that store from the chronos store data as the
                 *  selected store and returning the rest chronos stores
                 *  as the suggested stores.
                 */
                return {
                    selectedStore: storesWithUnavailableProducts[index],
                    suggestedStores: [
                        ...(storesWithUnavailableProducts?.slice(0, index) || []),
                        ...(storesWithUnavailableProducts?.slice(index + 1) || [])
                    ]
                };
            }
        } catch (err) {
            logError(err, false, 'getSelectedAndSuggestedStores');
            return {};
        }
    };

    const handleChange = (key, value) => {
        try {
            filterDispatch({ type: SET_VIEW_CART_FIELDS, key, value });
        } catch (error) {
            logError(error, false, 'handleChange');
        }
    };

    const getChronosStoresForValidation = updatedViewCart => {
        if (sortedChronosPcs) {
            let store;
            if (updatedViewCart?.isInStorePickup) {
                // sending selected store pc from sources for pickup
                const selectedStore = JSON.parse(
                    localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS) || '{}'
                );
                store = sortedChronosPcs?.find(store => store?.pc === selectedStore?.pc);
            } else {
                // sending first pc and not the selected store pc for delivery
                store = sortedChronosPcs?.[0];
            }
            return store ? [store] : [];
        }
        // sending null if sources call was not made, so it will be called during validation
        return null;
    };

    const onCheckoutHandler = async ({
        updatedViewCart = { ...viewCart },
        skipAVSAndSourcesCheck,
        availableCartItems
    }) => {
        try {
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART, JSON.stringify(updatedViewCart));
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.PROJECTDETAILS, JSON.stringify(projectDetails));
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS, isCreditNewAddress);
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISSOURCESLOADING, isSourcesLoading);
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CHECKOUTREFERRER, true);
            if (userType === USER_TYPE.CREDIT && isCreditNewAddress) {
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREATEJOBSITE, true);
            } else {
                localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREATEJOBSITE);
            }
            setIsCheckoutDetailsValidating(true);
            // validationDetails will have isValid value and other error details if validations fail
            const validationDetails = await validateCartDetails({
                chronosStores: getChronosStoresForValidation(updatedViewCart),
                skipAVSAndSourcesCheck,
                availableCartItems
            });
            setIsCheckoutDetailsValidating(false);
            if (validationDetails?.isValid) {
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISUSERDETAILSSET, true);
                redirectToCheckoutOrQuotePage(isEditQuoteFlow ? displayQuoteId : '');
            }
            return validationDetails;
        } catch (error) {
            logError(error, false, 'onCheckoutHandler');
            return { isValid: false };
        }
    };

    const getUnavailableDeliveryItemsFromSources = (chronosStores, availableCartItems) => {
        if (!overridePC?.pc && !viewCart?.isInStorePickup && isWithin72Hours()) {
            const unavailableItems = chronosStores?.[0]?.items?.filter(item => item?.availableQuantity <= 0) || [];
            if (unavailableItems?.length === 0) {
                return [];
            }
            const unavailableItemsSku = unavailableItems?.map(item =>
                generateSKU(item.equipmentCategory, item.equipmentClass)
            );
            const itemsChanged =
                availableCartItems?.filter(item => unavailableItemsSku?.find(sku => sku === item?.product?.sku)) || [];
            return itemsChanged?.map(item => ({
                product: item?.product,
                tileStatus: TILE_STATES.UNAVAILABLE
            }));
        }
        return [];
    };

    const validateCartDetails = async ({
        isCheckout,
        chronosStores,
        skipAVSAndSourcesCheck = false,
        availableCartItems = cart?.availableCartItems
    }) => {
        let storesData, isErrorFromAvs, avsAddressDetails;
        if (!skipAVSAndSourcesCheck) {
            ({ storesData, isErrorFromAvs, avsAddressDetails } = await computeChronosStoreWithAVS(chronosStores));
            if (isErrorFromAvs) {
                if (avsAddressDetails?.addrResult === 3) {
                    // resetting sources data to call again when there is multiple suggestions from avs
                    setSortedChronosPcs(null);
                }
                return { isValid: false, isErrorFromAvs };
            }
            if (storesData?.storesError || !storesData?.data?.data) {
                return { isValid: false, isStoreUnavailableOrError: true };
            }
            setAvsAndStoreData({ storesData, avsAddressDetails });
            if (!sortedChronosPcs) {
                setSortedChronosPcs(getMergedSourcesATPStores(storesData?.data?.data, storesData?.data?.data));
            }
            const unavailableItems = getUnavailableDeliveryItemsFromSources(storesData?.data?.data, availableCartItems);
            if (unavailableItems?.length > 0) {
                if (unavailableItems?.length === availableCartItems?.length) {
                    // if all items are unavailable from sources
                    return { isValid: false, isStoreUnavailableOrError: true };
                }
                // if few items are unavailable from sources
                return {
                    isValid: false,
                    unavailableItemsFromSourcesDetails: unavailableItems
                };
            }
        } else {
            // fetch previously calculated values for avs and sources if above check is skipped
            ({ storesData, avsAddressDetails } = avsAndStoreData || {});
        }
        const { isValid, nextAvailableSlot, endDateError, pickupSlotUnavailableDate } = await validateDateSlots({
            // windows call
            isCheckout,
            storesData,
            availableCartItems,
            avsAddressDetails
        });
        return {
            isValid,
            nextAvailableSlot,
            endDateError,
            pickupSlotUnavailableDate
        };
    };

    const triggerCheckoutStartEvent = () => {
        try {
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_STARTED,
                payloadEcommerceActionAnalytics()
            );
        } catch (error) {
            logError(error, false, 'triggerCheckoutStartEvent');
        }
    };

    const showCheckoutButton = () => {
        if (authorityType === AUTHORITY_TYPE.P2P) {
            return true;
        }
        if (userType === USER_TYPE.GUEST || userType === USER_TYPE.CASH) {
            return true;
        } else if (userProfile?.type === EMPLOYEE) {
            return false;
        } else {
            const currentAccount = userProfile?.accounts?.find(account => account?.id === userAccount?.accountNumber);
            return currentAccount?.permission?.[0]?.reservation === true;
        }
    };

    const inStorePickupHandler = () => {
        handleChange(VIEW_CART.IN_STORE, true);
        localStorage.setItem(
            STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART,
            JSON.stringify({
                ...viewCart,
                [VIEW_CART.IN_STORE]: true
            })
        );
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP, true);
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS);
        dispatch({ type: SET_RECOMPUTE_ITEM_AVAILABILITY, recomputeItemsAvailability: true });
    };

    return {
        fetchCartForCheckout,
        handleRemoveItem,
        handleCartQuantityUpdation,
        renderEmptyRentalLabels,
        renderCartItems,
        handleRedirection,
        initialiseSavedCart,
        recomputeEstimates,
        sortedChronosPcs,
        getSelectedAndSuggestedStores,
        handleChange,
        triggerCheckoutStartEvent,
        showCheckoutButton,
        onCheckoutHandler,
        isCheckoutDetailsValidating,
        showDiscrepencyStoreSelector,
        updateItemsFromSelectedChronosStore,
        renderCheckoutWithoutValidations,
        renderCheckoutWithValidations,
        getCartItems,
        inStorePickupHandler,
        isWithin72Hours,
        fetchTimezone
    };
};
