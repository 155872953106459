export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_FULFILLMENT_TYPE = 'SET_FULFILLMENT_TYPE';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_SELECTED_STORE_DETAILS = 'SET_SELECTED_STORE_DETAILS';
export const SET_PICKUP_STORES = 'SET_PICKUP_STORES';
export const SET_JOBSITES = 'SET_JOBSITES';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const RESET_FILTER_STATE = 'RESET_FILTER_STATE';
export const SET_ADDRESS = 'SET_ADDRESS';
export const UPDATE_FILTER_STATE = 'UPDATE_FILTER_STATE';
export const SET_SUGGESTED_JOBSITE_ADDRESS = 'SET_SUGGESTED_JOBSITE_ADDRESS';
export const SET_IS_SUGGESTIONS_LOADING = 'SET_IS_SUGGESTIONS_LOADING';
export const RESET_SUGGESTED_JOBSITE_ADDRESS = 'RESET_SUGGESTED_JOBSITE_ADDRESS';
export const SET_RECOMMENDED_JOBSITES = 'SET_RECOMMENDED_JOBSITES';
export const SET_IS_JOBSITE_RECOMMENDATION_LOADING = 'SET_IS_JOBSITE_RECOMMENDATION_LOADING';
export const EDIT_VIEW_CLOSE = 'EDIT_VIEW_CLOSE';
export const EDIT_VIEW_OPEN = 'EDIT_VIEW_OPEN';
export const SET_BSR_LIST = 'SET_BSR_LIST';
export const EDIT_VIEW_DETAILS = 'EDIT_VIEW_DETAILS';
export const SET_IS_CAP_DETAILS_UPDATING = 'SET_IS_CAP_DETAILS_UPDATING';
export const RESET_SELECTED_STORE_DETAILS = 'RESET_SELECTED_STORE_DETAILS';
export const SET_IS_FORM_STARTED_EVENT_TRIGGERED = 'SET_IS_FORM_STARTED_EVENT_TRIGGERED';

export const FULFILLMENT_TYPE = {
    DELIVERY: 'Delivery',
    PICKUP: 'Pickup',
    ROUNDTRIPDELIVERY: 'Round-trip delivery',
    INSTOREPICKUP: 'In-store pickup'
};

export const TILE_STATES = {
    AVAILABLE: 'AVAILABLE',
    CHANGE_STORE: 'CHANGE_STORE',
    CHANGE_DATES_GRT24HRS: 'CHANGE_DATES_GRT24HRS',
    CHANGE_DATES_GRT72HRS: 'CHANGE_DATES_GRT72HRS',
    CHANGE_DATES_OR_STORE_GRT72HRS: 'CHANGE_DATES_OR_STORE_GRT72HRS',
    CHANGE_DATES_OR_STORE_GRT24HRS: 'CHANGE_DATES_OR_STORE_GRT24HRS',
    EMPTY_LOCATION: 'EMPTY_LOCATION',
    UNAVAILABLE: 'UNAVAILABLE',
    EMPTY_LOCATION_AND_EMPTY_DATES: 'EMPTY_LOCATION_AND_EMPTY_DATES',
    EMPTY_DATES: 'EMPTY_DATES',
    CHANGE_DATES_AND_STORE_GRT24HRS: 'CHANGE_DATES_AND_STORE_GRT24HRS',
    CHANGE_DATES_AND_STORE_GRT72HRS: 'CHANGE_DATES_AND_STORE_GRT72HRS',
    AVAILABLE_WITH_WARNING: 'AVAILABLE_WITH_WARNING'
};

export const TIER1_STORES_KEYS = {
    CI: 'ci',
    ATP: 'atp',
    ADJ_ATP: 'adjatp'
};

export const STORE_VARIANT = {
    CAP_HEADER: 'cap-header'
};

export const STORE_TYPE = {
    INVENTORY: 'Inventory',
    ADJATP: 'AdjATP',
    ATP: 'ATP'
};

export const USER_CANCELLED_REQUEST_MSG = ['The user aborted a request.', 'signal is aborted without reason'];
export const COACHMARK_TYPE = {
    LOCATION: 'location',
    PICK_STORE: 'pickstore',
    PRODUCT_TILE: 'producttile'
};
