import React, { useContext } from 'react';
import { cookieValue } from '../aem-core-components/utils/cookieUtils';
import { getEnv } from '../components/global/utils/logger';
import { ECOMMERCE_PAYLOAD_FUNC } from '../constants/analyticsConstants/Ecommerce';
import { PAGE_PAYLOAD_FUNC } from '../constants/analyticsConstants/Page';
import { VARIABLE_CONFIG } from '../constants/analyticsConstants/Variables';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { usePageType } from '../hooks/usePageType';

export const AnalyticsContext = React.createContext();

const GoogleTagManagerEvents = props => {
    window.dataLayer = window.dataLayer || [];
    const isGTMHidden = JSON.parse(document.querySelector('meta[name="hideGTM"]')?.content || true);
    const gtmValue = document.querySelector('meta[data-endpoint-key="GTMMode"]')?.dataset?.endpointValue;
    const sbmId = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SBMID);
    const sbsId = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SBSID);
    const sbuidCookie = cookieValue('sbuid') || '';
    const sbaidCookie = cookieValue('sbaid') || '';
    const pageType = usePageType();

    const getEnvValue = () => {
        return gtmValue === VARIABLE_CONFIG.DOMAIN.PRODUCTION || getEnv() === VARIABLE_CONFIG.DOMAIN.PRODUCTION
            ? VARIABLE_CONFIG.ENVIRONTMENT.LIVE
            : VARIABLE_CONFIG.ENVIRONTMENT.TEST;
    };

    const analyticsProperties = {
        sendEventsForPageUser: (eventName, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForPageElementClick: (type, action, style, text, placement, modal, selectMethod, sunbeltLocation) => {
            PAGE_PAYLOAD_FUNC.payloadForPageElementClick(
                type,
                action,
                style,
                text,
                placement,
                modal,
                selectMethod,
                sunbeltLocation,
                (eventName, payload) => {
                    if (!isGTMHidden) {
                        window.dataLayer.push({
                            event: eventName,
                            [eventName]: payload
                        });
                    }
                }
            );
        },
        sendEventsForPageContent: (post, listings, posts) => {
            PAGE_PAYLOAD_FUNC.payloadForPageContent(post, listings, posts, (eventName, payload) => {
                if (!isGTMHidden) {
                    window.dataLayer.push({
                        event: eventName,
                        [eventName]: payload
                    });
                }
            });
        },
        sendEventsForPageUserError: (errorType, errorLocation, errorMessage) => {
            PAGE_PAYLOAD_FUNC.payloadForPageUserError(errorType, errorLocation, errorMessage, (eventName, payload) => {
                if (!isGTMHidden) {
                    window.dataLayer.push({
                        event: eventName,
                        [eventName]: payload
                    });
                }
            });
        },
        sendEventsForError: (errorType, errorMessage) => {
            PAGE_PAYLOAD_FUNC.payloadForError(errorType, errorMessage, payload => {
                if (!isGTMHidden) {
                    window.dataLayer.push(payload);
                }
            });
        },
        sendEventsForClick: (event, ecommerce, eventCategory, eventAction, eventLabel) => {
            PAGE_PAYLOAD_FUNC.payloadForSendEventForClick(
                event,
                ecommerce,
                eventCategory,
                eventAction,
                eventLabel,
                payload => {
                    if (!isGTMHidden) {
                        window.dataLayer.push(payload);
                    }
                }
            );
        },
        sendEventsForEcommercePageData: (eventName, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForEcommerceProductClick: (list, products) => {
            ECOMMERCE_PAYLOAD_FUNC.payloadForEcommerceProductClick(list, products, (eventName, payload) => {
                if (!isGTMHidden) {
                    window.dataLayer.push({
                        event: eventName,
                        [VARIABLE_CONFIG.EVENT_STRUCTURE.ECOMMERCE]: {
                            [VARIABLE_CONFIG.EVENT_STRUCTURE.CLICK]: payload
                        }
                    });
                }
            });
        },
        sendEventsForEcommerceDetail: (list, products, availability) => {
            ECOMMERCE_PAYLOAD_FUNC.payloadForEcommerceDetail(list, products, availability, (eventName, payload) => {
                if (!isGTMHidden) {
                    window.dataLayer.push({
                        event: eventName,
                        [VARIABLE_CONFIG.EVENT_STRUCTURE.ECOMMERCE]: {
                            [VARIABLE_CONFIG.EVENT_STRUCTURE.DETAIL]: payload
                        }
                    });
                }
            });
        },
        sendEventsForEcommerceAdd: (list, products) => {
            ECOMMERCE_PAYLOAD_FUNC.payloadForEcommerceAdd(list, products, (eventName, payload) => {
                if (!isGTMHidden) {
                    window.dataLayer.push({
                        event: eventName,
                        [VARIABLE_CONFIG.EVENT_STRUCTURE.ECOMMERCE]: {
                            [VARIABLE_CONFIG.EVENT_STRUCTURE.ADD]: payload
                        }
                    });
                }
            });
        },
        sendEventsForEcommerceRemove: (list, products) => {
            ECOMMERCE_PAYLOAD_FUNC.payloadForEcommerceRemove(list, products, (eventName, payload) => {
                if (!isGTMHidden) {
                    window.dataLayer.push({
                        event: eventName,
                        [VARIABLE_CONFIG.EVENT_STRUCTURE.ECOMMERCE]: {
                            [VARIABLE_CONFIG.EVENT_STRUCTURE.REMOVE]: payload
                        }
                    });
                }
            });
        },
        sendEventsForEcommerceQuantity: (list, products) => {
            ECOMMERCE_PAYLOAD_FUNC.payloadForEcommerceQuantity(list, products, (eventName, payload) => {
                if (!isGTMHidden) {
                    window.dataLayer.push({
                        event: eventName,
                        [eventName]: payload
                    });
                }
            });
        },
        sendEventsForEcommerceCheckout: (step, products) => {
            ECOMMERCE_PAYLOAD_FUNC.payloadForEcommerceCheckout(step, products, (eventName, payload) => {
                if (!isGTMHidden) {
                    window.dataLayer.push({
                        event: eventName,
                        [VARIABLE_CONFIG.EVENT_STRUCTURE.ECOMMERCE]: {
                            [VARIABLE_CONFIG.EVENT_STRUCTURE.CHECKOUT]: payload
                        }
                    });
                }
            });
        },
        sendEventsForEcommerceAction: (eventName, payload, additionalPayload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    ...payload,
                    ...additionalPayload
                });
            }
        },
        sendEventsForDatePickerAction: (eventName, formName, fieldName, formError) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name: formName,
                    form_field: fieldName,
                    form_error: formError
                });
            }
        },
        sendEventsForLocationAction: (eventName, formName, fieldName, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name: formName,
                    form_field: fieldName,
                    ...payload
                });
            }
        },
        sendEventsForAddressErrorAction: (eventName, formName, fieldName, formError, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name: formName,
                    form_field: fieldName,
                    form_error: formError,
                    ...payload
                });
            }
        },
        sendEventsForLocationFormSubmit: (eventName, formName, linkName, linkType, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name: formName,
                    link_name: linkName,
                    link_type: linkType,
                    ...payload
                });
            }
        },
        sendEventsForLocationFormSubmitComplete: (eventName, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForAlternateInventoryList: (eventName, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForAlternateInventoryView: (
            eventName,
            link_name,
            link_type,
            link_location,
            link_destination,
            payload
        ) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    link_name,
                    link_type,
                    link_location,
                    link_destination,
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForCtaClicked: (eventName, link_name, link_type, link_location, link_destination) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    link_name,
                    link_type,
                    link_location,
                    link_destination,
                    event: eventName
                });
            }
        },
        sendEventsForUnavailableCta: (
            eventName,
            form_name,
            link_name,
            link_type,
            link_location,
            link_destination,
            payload
        ) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name,
                    link_name,
                    link_type,
                    link_location,
                    link_destination,
                    ...payload
                });
            }
        },
        sendEventsForDatePickerFormViewed: (eventName, form_name, field_name, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    field_name,
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForDatePickerFormInteraction: (eventName, form_name, field_name, field_value, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    field_name,
                    field_value,
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForFormCompleted: (eventName, form_name, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForDatePickerFormReset: (eventName, form_name, link_name, link_type, link_location) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    link_name,
                    link_type,
                    link_location,
                    event: eventName
                });
            }
        },
        sendEventsForEcommerceCheckoutOption: (list, option) => {
            ECOMMERCE_PAYLOAD_FUNC.payloadForEcommerceCheckoutOption(list, option, (eventName, payload) => {
                if (!isGTMHidden) {
                    window.dataLayer.push({
                        event: eventName,
                        [VARIABLE_CONFIG.EVENT_STRUCTURE.ECOMMERCE]: {
                            [VARIABLE_CONFIG.EVENT_STRUCTURE.CHECKOUT_OPTION]: payload
                        }
                    });
                }
            });
        },
        sendEventsForEcommercePurchase: (
            currencyCode,
            id,
            revenue,
            tax,
            subtotal,
            otherFees,
            rentalProtectionPlan,
            fuelConveniencePlan,
            environmentalFee,
            deliveryFee,
            transportationSurcharge,
            zipBill,
            zipShip,
            payMethod,
            rentalMethod,
            reservationResult,
            rentalDuration,
            sunbeltLocation,
            products
        ) => {
            ECOMMERCE_PAYLOAD_FUNC.payloadForEcommercePurchase(
                currencyCode,
                id,
                revenue,
                tax,
                subtotal,
                otherFees,
                rentalProtectionPlan,
                fuelConveniencePlan,
                environmentalFee,
                deliveryFee,
                transportationSurcharge,
                zipBill,
                zipShip,
                payMethod,
                rentalMethod,
                reservationResult,
                rentalDuration,
                sunbeltLocation,
                products,
                (eventName, payload) => {
                    if (!isGTMHidden) {
                        window.dataLayer.push({
                            event: eventName,
                            [VARIABLE_CONFIG.EVENT_STRUCTURE.ECOMMERCE]: payload
                        });
                    }
                }
            );
        },
        sendEventsForEcommercePage: (type, currencyCode) => {
            ECOMMERCE_PAYLOAD_FUNC.payloadForEcommercePage(type, currencyCode, (eventName, payload) => {
                if (!isGTMHidden) {
                    window.dataLayer.push({
                        event: eventName,
                        ecommerce: payload
                    });
                }
            });
        },
        sendEventsForUpdateVirtualPath: (virtualPageTitle, virtualPageURL) => {
            ECOMMERCE_PAYLOAD_FUNC.payloadForUpdateVirtualPath(
                virtualPageTitle,
                virtualPageURL,
                (eventName, payload) => {
                    if (!isGTMHidden) {
                        window.dataLayer.push({
                            event: eventName,
                            ...payload
                        });
                    }
                }
            );
        },
        sendEventsForPageLoad: (eventName, payload) => {
            analyticsProperties?.sendEventsForPageUser(eventName, payload);
        },
        sendEventsForMarketoForm: (eventName, form_id, cat_class, category_name, subject_of_request) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_id,
                    cat_class,
                    category_name,
                    subject_of_request
                });
            }
        },
        sendEventsForCcaPageInteraction: (eventName, form_name, page_title, page_location, step_number, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    page_title,
                    page_location,
                    step_number,
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForCcaStepCompleted: (eventName, form_name, page_title, page_location, step_number, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    page_title,
                    page_location,
                    step_number,
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForApplicationStatus: (eventName, application_id, cca_submission_id, page_title, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    application_id,
                    cca_submission_id,
                    page_title,
                    event: eventName,
                    ...payload
                });
            }
        },

        sendEventsForCcaStepFormSubmitted: (
            eventName,
            form_name,
            page_title,
            page_location,
            step_number,
            cca_submission_id,
            payload
        ) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    page_title,
                    page_location,
                    step_number,
                    cca_submission_id,
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForCcaStepFormStarted: (
            eventName,
            form_name,
            field_name,
            page_title,
            page_location,
            step_number,
            cca_submission_id,
            payload
        ) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    field_name,
                    page_title,
                    page_location,
                    step_number,
                    cca_submission_id,
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForCcaStepFormError: (
            eventName,
            form_name,
            form_error,
            form_field,
            page_title,
            page_location,
            step_number,
            cca_submission_id,
            payload
        ) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    form_error,
                    form_field,
                    page_title,
                    page_location,
                    step_number,
                    cca_submission_id,
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForApplicationError: (eventName, cca_submission_id, page_title, error) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    cca_submission_id,
                    page_title,
                    event: eventName,
                    error
                });
            }
        },
        sendEventsForStoreModal: (eventName, pcWasSet, pcNumber, pcResults) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    pc_was_set: pcWasSet,
                    pc_number: pcNumber,
                    pc_results: pcResults
                });
            }
        },
        sendEventsForAddToCart: (eventName, currency, value, items) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    ecommerce: {
                        currency,
                        value,
                        items
                    }
                });
            }
        },
        sendEventsForNoStoreNearby: (eventName, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({ event: eventName, ...payload });
            }
        },
        sendEventsForModalOpened: (eventName, form_name, form_type, form_error, lookup_results, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    form_type,
                    form_error,
                    lookup_results,
                    event: eventName,
                    ...payload
                });
            }
        },

        sendEventsForGRModalOpened: (eventName, form_name, form_type, form_error, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    form_type,
                    form_error,
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForModalClosed: (eventName, link_name, link_type, link_location, link_destination, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    link_name,
                    link_type,
                    link_location,
                    link_destination,
                    event: eventName,
                    ...payload
                });
            }
        },

        sendEventsForModalCompleted: (event, form_name, form_type, field_name, lookup_results, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event,
                    form_name,
                    form_type,
                    field_name,
                    lookup_results,
                    ...payload
                });
            }
        },

        sendEventsForGRModalCompleted: (event, form_name, form_type, form_field, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event,
                    form_name,
                    form_type,
                    form_field,
                    ...payload
                });
            }
        },

        sendEventsForGRModalCTAClicked: (event, link_name, link_type, link_location, link_destination, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event,
                    link_name,
                    link_type,
                    link_location,
                    link_destination,
                    ...payload
                });
            }
        },
        sendEventsForCcaVerifyAddressModal: (event, form_name, form_type, field_name, lookup_results, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event,
                    form_name,
                    form_type,
                    field_name,
                    lookup_results,
                    ...payload
                });
            }
        },
        sendEventsForFavoritesViewed(event, number_of_results, sort_by) {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event,
                    number_of_results,
                    sort_by
                });
            }
        },
        sendEventsForUnavailableItem: (eventName, reason) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    alert_reason: reason
                });
            }
        },
        sendEventsForP2PCtaClick(eventName, link_name, link_type, link_location, link_destination, payload) {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    link_name,
                    link_type,
                    link_location,
                    link_destination,
                    ...payload
                });
            }
        },
        sendEventsForWishlistPageViewed: (eventName, pageLocation, pagetitle) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    page_location: pageLocation,
                    page_title: pagetitle
                });
            }
        },
        sendEventsForCtaChange: (
            eventName,
            link_name,
            link_type,
            link_location,
            link_destination,
            cat_class,
            position,
            payload = {}
        ) => {
            if (!isGTMHidden) {
                const eventData = {
                    event: eventName,
                    link_name,
                    link_type,
                    link_location,
                    link_destination,
                    cat_class,
                    ...(isNaN(position) ? {} : { position }),
                    ...payload
                };
                window.dataLayer.push(eventData);
            }
        },
        sendEventsForP2PApplication: (eventName, payload) => {
            analyticsProperties?.sendEventsForPageUser(eventName, payload);
        },
        sendEventsForViewItemList: (eventName, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForAvailabilityToggle: (eventName, toggle_name, toggle_value) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    toggle_name,
                    toggle_value
                });
            }
        },
        sendEventsForProductCard: (
            event,
            link_name,
            link_type,
            link_location,
            link_destination,
            cat_class,
            list_id,
            list_name,
            position
        ) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event,
                    link_name,
                    link_type,
                    link_location,
                    link_destination,
                    cat_class,
                    list_id,
                    list_name,
                    position
                });
            }
        },

        sendEventsForEmailVerifyFormStarted: (eventName, form_name, account_type, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name,
                    account_type,
                    ...payload
                });
            }
        },

        sendEventsForEmailVerifyFormSubmitted: (eventName, form_name, account_type, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    account_type,
                    event: eventName,
                    ...payload
                });
            }
        },

        sendEventsForEmailVerifyFormError: (eventName, form_name, form_error, account_type, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    form_error,
                    account_type,
                    event: eventName,
                    ...payload
                });
            }
        },

        sendEventsForEmailVerifyFormCompleted: (eventName, form_name, account_type, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    account_type,
                    event: eventName,
                    ...payload
                });
            }
        },

        sendEventsForGuidedRegistrationFormViewed: (eventName, form_name, account_type, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name,
                    account_type,
                    ...payload
                });
            }
        },

        sendEventsForGuidedRegistrationFormStarted: (eventName, form_name, field_name, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name,
                    field_name,
                    ...payload
                });
            }
        },

        sendEventsForGuidedRegistrationFormError: (eventName, form_name, form_error, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name,
                    form_error,
                    ...payload
                });
            }
        },

        sendEventsForGuidedRegistrationFormSubmited: (eventName, form_name, account_type, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name,
                    account_type,
                    ...payload
                });
            }
        },

        sendEventsForPageViewed: (eventName, page_title, page_location, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    page_title,
                    page_location,
                    ...payload
                });
            }
        },

        sendEventsForGuidedRegistrationFormCompleted: (eventName, form_name, account_type, form_field, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name,
                    account_type,
                    form_field,
                    ...payload
                });
            }
        },
        sendEventsForCreateCashForm: (eventName, form_name, field_name, step_number, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name,
                    field_name,
                    step_number,
                    ...payload
                });
            }
        },

        sendEventsForCreateCashFormError: (eventName, form_name, field_name, form_error, step_number, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name,
                    field_name,
                    form_error,
                    step_number,
                    ...payload
                });
            }
        },

        sendEventsForCreateCashFormStepViewed: (
            eventName,
            form_name,
            page_title,
            page_location,
            step_number,
            payload
        ) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    form_name,
                    page_title,
                    page_location,
                    step_number,
                    event: eventName,
                    ...payload
                });
            }
        },

        sendEventsForCartModalOpen: (eventName, form_name, unavailable_items, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    event: eventName,
                    form_name,
                    unavailable_items,
                    ...payload
                });
            }
        },
        sendEventsForCartModalCtaClick: (
            eventName,
            form_name,
            link_name,
            link_type,
            link_location,
            link_destination,
            unavailable_items,
            payload
        ) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    link_name,
                    form_name,
                    link_type,
                    link_location,
                    link_destination,
                    unavailable_items,
                    event: eventName,
                    ...payload
                });
            }
        },
        sendEventsForCartModalClosed: (eventName, link_name, link_type, link_location, link_destination, payload) => {
            if (!isGTMHidden) {
                window.dataLayer.push({
                    link_name,
                    link_type,
                    link_location,
                    link_destination,
                    event: eventName,
                    ...payload
                });
            }
        }
    };

    return <AnalyticsContext.Provider value={analyticsProperties}>{props.children}</AnalyticsContext.Provider>;
};

export default GoogleTagManagerEvents;

export const useAnalyticsContext = () => useContext(AnalyticsContext);
