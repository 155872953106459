import { useEffect, useState } from 'react';
import { useFilterState } from '../filterContext';
import { useCapUtils } from './useCapUtils';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import { useRentalCoachmark } from './useRentalCoachmark';
import { useCapAnalytics } from './useCapAnalytics';
import isObjectEmpty from '../../../aem-core-components/utils/isObjectEmpty';
import { updateSelectedStoreDetailsForRoundTrip } from '../../global/utils/computeAddressUtil';
import { formatNearbyPC, formatStoreDataToObject, isTier2Radius } from '../../global/utils/commonUtils';
import { isValidString, logError } from '../../global/utils/logger';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { FULFILLMENT_TYPE, SET_PICKUP_STORES, SET_SELECTED_STORE_DETAILS } from '../constants';
import { SET_LOCATION_LOADING, SET_VIEW_CART_FIELDS } from '../../../aem-core-components/actions/constants';
import { VIEW_CART } from '../../../constants/cartConstants';
import { GENERAL_EQUIPMENT_AND_TOOLS } from '../../location/constants';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';

export const usePickupStore = (locationDetails, companyID) => {
    const [{ viewCart, selectedStoreDetails, pickupStores }, dispatch] = useFilterState();
    const { getStoresData } = useCapUtils();
    const { fetchLocationCoordinates, isSelectedLocationEmpty } = useCheckLocationEmpty();
    const fulfillmentData = viewCart?.isInStorePickup ? FULFILLMENT_TYPE.PICKUP : FULFILLMENT_TYPE.DELIVERY;
    const [fulfillmentValue, setFulfillmentValue] = useState(fulfillmentData);
    const [selectedPickupStore, setSelectedPickupStore] = useState({});
    const [storesData, setStoresData] = useState(null);
    const [noLocationPC, setNoLocationPC] = useState(false);
    const [showPickupStoreAlert, setShowPickupStoreAlertModal] = useState(false);
    const [showAlertBanner, setShowAlertBanner] = useState(false);
    const { handleLocationTooltipClose } = useRentalCoachmark(selectedPickupStore);
    const { formInteractionStartedEventAnalytics, triggerFormInteractedEvent } = useCapAnalytics();
    const { tieroneinvradius, tiertwoinvradius } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const tier1SearchRadius = tieroneinvradius || 100;
    const tier2SearchRadius = tiertwoinvradius || 500;
    const [allStoresDataWithDistance, setAllStoresDataWithDistance] = useState({
        distance: tier1SearchRadius,
        stores: []
    });
    const cidPcList = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST) || '[]');
    const [ratesPCs, setRatesPCs] = useState(cidPcList);
    const atpPcList = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST) || '[]');
    const [localAtpPcs, setLocalAtpPcs] = useState(atpPcList);
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');

    useEffect(() => {
        setFulfillmentValue(fulfillmentData);
    }, [fulfillmentData, overridePC?.pc]);

    const handleShowAlertBanner = prop => {
        if (isSelectedLocationEmpty()) {
            setShowAlertBanner(false);
        } else if (isObjectEmpty(prop)) {
            setShowAlertBanner(true);
        } else {
            setShowAlertBanner(false);
        }
    };

    /** using stringified selectedStoreDetails as a dependency as when user comes back from checkout,
     *  pc will be same, but drivingDistanceFromJobsite will change to distance,
     *  so, selectedStoreDetails.pc as a dependency will not trigger this useEffect
     */
    useEffect(() => {
        setSelectedPickupStore(selectedStoreDetails);
        handleShowAlertBanner(selectedStoreDetails);
    }, [JSON.stringify(selectedStoreDetails)]);

    useEffect(() => {
        /* Set the stores data and banner for tier2 radius when page reloads */
        const tier2Radius = isValidString(isTier2Radius()) && !overridePC?.pc;
        const stores = Object.values(pickupStores || {});
        if (pickupStores) {
            setStoresData({ data: tier2Radius ? [] : stores?.slice(0, 200) });
        }
        setAllStoresDataWithDistance({
            stores,
            distance: tier2Radius ? tier2SearchRadius : tier1SearchRadius
        });
        updateRatesPcs();
        setNoLocationPC(tier2Radius);
        setShowAlertBanner(tier2Radius);
    }, [pickupStores]);

    const isLocationChanged = (lat = locationDetails?.lat, long = locationDetails?.long) => {
        const { localLat, localLong } = fetchLocationCoordinates();
        return localLat !== lat || localLong !== long;
    };

    const setShowPickupStoreAlert = showModal => {
        if (!overridePC?.pc) {
            setShowPickupStoreAlertModal(showModal);
        }
    };

    const updateRatesPcs = (ratePcs, atpPcs, companyId = companyID) => {
        if (ratePcs) {
            const formattedPcs = ratePcs?.map(data => formatNearbyPC(data?.pc, `0${companyId}`));
            setRatesPCs(formattedPcs);
        } else {
            setRatesPCs(cidPcList);
        }
        if (atpPcs) {
            const formattedPcs = atpPcs?.map(data => formatNearbyPC(data?.pc, `0${companyId}`));
            setLocalAtpPcs(formattedPcs);
        } else {
            setLocalAtpPcs(atpPcList);
        }
    };

    const handleStoreData = (pcList, selectStoreFromContext = false) => {
        setStoresData({ data: pcList?.slice(0, 200) });
        if (selectStoreFromContext) {
            const selectedStore = pcList?.find(item => item?.pc === selectedPickupStore?.pc);
            setSelectedPickupStore(selectedStore || {});
        } else {
            const specialities = pcList?.find(
                item =>
                    !item?.specialties ||
                    item?.specialties?.length === 0 ||
                    item?.specialties?.[0] === GENERAL_EQUIPMENT_AND_TOOLS
            );
            const storeData = specialities || pcList?.[0] || {};
            if (fulfillmentValue === FULFILLMENT_TYPE.DELIVERY) {
                updateSelectedStoreDetailsForRoundTrip(storeData);
            }
            setSelectedPickupStore(storeData || {});
        }
    };

    /* This function is used for the locations an set the default store call when address or jobsite is changed form CAP location header*/
    const getStores = async (lat = '', long = '', companyId, selectStoreFromContext = false) => {
        try {
            if (lat && long) {
                const { pricingPcs, pickupStorePcs, distance } = await getStoresData(lat, long, companyId);
                updateRatesPcs(pricingPcs, pickupStorePcs, companyId);
                handleStoreData(
                    distance === tier1SearchRadius ? pickupStorePcs : [],
                    selectStoreFromContext
                );
                setAllStoresDataWithDistance({ stores: pickupStorePcs, distance });
                setNoLocationPC(distance === tier2SearchRadius);
                /** will dispatch stores only if the context value is null, indicating the pickup stores don't have data
                 *  if pickupStores is empty array, it indicates that location api is called
                 */
                if (!pickupStores && !isLocationChanged(lat, long)) {
                    dispatch({
                        type: SET_PICKUP_STORES,
                        pickupStores: formatStoreDataToObject(pickupStorePcs) || {}
                    });
                }
                dispatch({ type: SET_LOCATION_LOADING, isLocationsLoading: false });
            } else {
                updateRatesPcs([], []);
                setAllStoresDataWithDistance({ stores: [], distance: tier1SearchRadius });
                setStoresData({ data: [] });
                setSelectedPickupStore({});
                setNoLocationPC(false);
            }
        } catch (error) {
            logError(error, false, 'getStores');
        }
    };

    const updateStoreOnFulfillmentChange = async () => {
        const pcList = storesData?.data;
        if (
            !storesData &&
            locationDetails?.lat &&
            allStoresDataWithDistance?.distance != tier2SearchRadius
        ) {
            await getStores(locationDetails?.lat, locationDetails?.long, companyID);
        } else if (pcList?.length > 0) {
            handleStoreData(pcList);
        }
    };

    const updateAllStoreDetails = () => {
        if (allStoresDataWithDistance?.distance == tier1SearchRadius) {
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS);
        } else {
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS, true);
        }
        dispatch({
            type: SET_PICKUP_STORES,
            pickupStores: formatStoreDataToObject(allStoresDataWithDistance?.stores || []) || {}
        });
    };

    const resetFulfillmentStores = () => {
        setFulfillmentValue(viewCart?.isInStorePickup ? FULFILLMENT_TYPE.PICKUP : FULFILLMENT_TYPE.DELIVERY);
        setSelectedPickupStore(selectedStoreDetails);
        handleShowAlertBanner(selectedStoreDetails);
        updateRatesPcs();
        const tier2Radius = isValidString(isTier2Radius()) && !overridePC?.pc;
        const stores = Object.values(pickupStores || {});
        if (pickupStores) {
            setStoresData({ data: tier2Radius ? [] : stores?.slice(0, 200) });
        } else {
            /** when pickupStores is null then making stores data as null,
             *  this will call the locations api when user clicks on pickup store modal
             */
            setStoresData(null);
        }
        setAllStoresDataWithDistance({
            stores,
            distance: tier2Radius ? tier2SearchRadius : tier1SearchRadius
        });
    };

    const updateStoresInContext = () => {
        if (storesData) {
            updateAllStoreDetails();
            dispatch({
                type: SET_SELECTED_STORE_DETAILS,
                selectedStoreDetails: selectedPickupStore,
                localLat: locationDetails?.lat,
                localLong: locationDetails?.long,
                isNoStore:
                    allStoresDataWithDistance?.distance == tier2SearchRadius
                        ? allStoresDataWithDistance?.stores[0]
                        : {}
            });
        }
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST, JSON.stringify(ratesPCs));
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST, JSON.stringify(localAtpPcs));
    };

    const updateFulfillmentDetails = () => {
        dispatch({
            type: SET_VIEW_CART_FIELDS,
            key: VIEW_CART.IN_STORE,
            value: fulfillmentValue === FULFILLMENT_TYPE.PICKUP
        });
        dispatch({
            type: SET_VIEW_CART_FIELDS,
            key: 'showDeliveryEstimate',
            value: fulfillmentValue === FULFILLMENT_TYPE.DELIVERY
        });
    };

    const isFulfillmentTypeChanged = () => {
        return fulfillmentValue !== fulfillmentData;
    };

    const isStoreDetailsChanged = () => {
        return selectedStoreDetails?.pc !== selectedPickupStore?.pc;
    };

    const rentalLocationFocusHandler = () => {
        handleLocationTooltipClose();
        formInteractionStartedEventAnalytics(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LOCATION);
        triggerFormInteractedEvent(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LOCATION);
    };

    return {
        fulfillmentValue,
        setFulfillmentValue,
        storesData,
        noLocationPC,
        setNoLocationPC,
        showPickupStoreAlert,
        setShowPickupStoreAlert,
        showAlertBanner,
        setShowAlertBanner,
        selectedPickupStore,
        setSelectedPickupStore,
        allStoresDataWithDistance,
        ratesPCs,
        localAtpPcs,
        getStores,
        resetFulfillmentStores,
        updateStoresInContext,
        updateFulfillmentDetails,
        updateStoreOnFulfillmentChange,
        isFulfillmentTypeChanged,
        isStoreDetailsChanged,
        rentalLocationFocusHandler
    };
};
