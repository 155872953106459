import React, { useEffect, useState } from 'react';
import { SET_CREDIT_NEWADDR_FLAG, SHOW_ACCOUNT_OVERLAY } from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../contexts/cart';
import { SCREEN_NAME } from '../../../../constants/screenConstants';
import CreditLocationOverlay from './CreditLocationOverlay';
import './locationOverlay.scss';

const LocationOverlayModal = props => {
    const { handleLocationOverlay, showLocationModal } = props;
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [{ showAccountOverlay }, dispatch] = useCartState();
    const body = document.querySelector('body');

    const setIsCreditNewAddress = value => {
        dispatch({
            type: SET_CREDIT_NEWADDR_FLAG,
            isCreditNewAddress: value
        });
    };

    useEffect(() => {
        if (showAccountModal) {
            body.classList.add('overflow-hidden');
        } else {
            body.classList.remove('overflow-hidden');
        }
    }, [showAccountModal]);

    const handleAccountOverlay = closeModal => {
        if (!closeModal) {
            dispatch({
                type: SHOW_ACCOUNT_OVERLAY,
                showAccountOverlay: false
            });
        }
        setShowAccountModal(closeModal);
    };

    useEffect(() => {
        if (showAccountOverlay) {
            handleAccountOverlay(true);
        } else {
            handleAccountOverlay(false);
        }
    }, [showAccountOverlay]);

    const renderLocationOverlayModal = () => {
        return (
            <CreditLocationOverlay
                screenName={SCREEN_NAME.PDP_SCREEN}
                showLocationOverlayModal={showLocationModal}
                showAccountOverlayModal={showAccountModal}
                handleLocationOverlay={handleLocationOverlay}
                handleAccountOverlay={handleAccountOverlay}
                setIsCreditNewAddress={setIsCreditNewAddress}
            />
        );
    };

    return <div>{renderLocationOverlayModal()}</div>;
};

export default React.memo(LocationOverlayModal);
