import { useMutation } from '@apollo/client';
import moment from 'moment';
import {
    createOrTransmitQuotePayloadProcess,
    createPunchoutProcess,
    createReservationProcess,
    editOrSaveQuotePayloadProcess,
} from '../../../aem-core-components/actions/cart';
import { SET_SUBMIT_RESERVATION } from '../../../aem-core-components/actions/constants';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import CREATE_OR_TRANSMIT_QUOTE from '../../../aem-core-components/queries/mutation_create_or_transmit_quote.graphql';
import CREATE_PUNCHOUT_ORDER from '../../../aem-core-components/queries/mutation_create_punchout_order.graphql';
import CREATE_RESERVATION_MUTATION from '../../../aem-core-components/queries/mutation_create_reservation';
import EDIT_OR_SAVE_QUOTE from '../../../aem-core-components/queries/mutation_edit_or_save_quote.graphql';
import { useCookieValue } from '../../../aem-core-components/utils/hooks';
import { getClientIp, getSubmissionId } from '../../../components/guidedReg/api/apiRequest';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { DELIVERY_CHARGE_FLAG } from '../../../constants/cartConstants';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { useCartState } from '../../../contexts/cart';
import { useCheckDeliveryCharge } from '../../../hooks/useCheckDeliveryCharge';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import { useSunbeltLocation } from '../../../hooks/useSunbeltLocation';
import { useFilterState } from '../../cap';
import { createDummyGuestAccount, createGuestAccount } from '../../global/api/CommonResponseHandler';
import { AUTHORITY_TYPE } from '../../global/constants';
import { calcRQFlagStatus, clearLocalStorage, generateSKU } from '../../global/utils/commonUtils';
import { isValidString, logError } from '../../global/utils/logger';
import { refreshTokenCall } from '../../guidedReg/utils/guidedUtil';
import { getRatesByOwnedPc } from '../../pdp/api/getRates';
import useQuoteDetails from '../../quotes/hooks/useQuoteDetails';
import { cardTypeMapper } from '../checkoutPayment/paymentHelper';
import {
    DUKE_CORP_LINK,
    FORCE_ITEM,
    FULFILLMENT_TYPE_DELIVERY,
    FULFILLMENT_TYPE_PICKUP,
    RENTAL_PROTECTION_PLAN,
    SBRWEB,
    SUCCESS_CODE_200,
    TRANSMIT_NOW,
} from '../constants';
import {
    convertDateToMomentDateTimeNonUTC,
    handleAnonymousIdFormatting,
    makeArrayFromObjects,
} from '../utils/checkoutUtils';
import useFulfillmentHooks from '../utils/useFulFillmentHooks';
import { getDistance } from '../../cart/utils/helper';

const useReservation = () => {
    const [
        {
            cart,
            cartId,
            howToGetYourOrderDetails,
            optionalPlan,
            consumables,
            userAccount,
            paymentData,
            currentOffset,
            guestUserDetails,
            projectInfo,
            customBillingFields,
            paymentTokenData,
            tbdModal,
            punchoutUserData,
            selectedTransmitOrSave,
            orderSummaryDetails
        },
        dispatch
    ] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate }, filterDispatch] = useFilterState();
    const { selectedStoreDetails, estimatedTotal } = howToGetYourOrderDetails;
    const { fulfillmentPercent, isOpenOnDateOut, isLastResortBranch } =
        howToGetYourOrderDetails?.selectedStoreDetails || {};
    const { isInStorePickup, pc } = viewCart;
    const {
        projectName,
        projectAddress1,
        projectAddress2,
        selectedProjectJobId,
        selectedProjectState,
        selectedProjectCity,
        selectedProjectZip,
        selectedRMJobId
    } = projectDetails || {};
    const { phoneNumber, accessNotes, poNumber } = projectDetails || {};
    const [userState, { logoutUser }] = useUserContext();
    const { userProfile } = userState;
    const { isRPPChecked, isFuelChargeChecked } = optionalPlan;
    const orderEstimates = cart?.estimatesResponse?.estimate;
    const { deliveryPickUpCharges } = orderEstimates?.totals || {};
    const [createReservationMutation] = useMutation(CREATE_RESERVATION_MUTATION);
    const [createPunchoutOrderMutation] = useMutation(CREATE_PUNCHOUT_ORDER);
    const [createOrTransmitQuoteMutation] = useMutation(CREATE_OR_TRANSMIT_QUOTE);
    const [editOrSaveQuoteMutation] = useMutation(EDIT_OR_SAVE_QUOTE);
    const userType = useCheckUser();
    const deliveryChargeFlag = useCheckDeliveryCharge();
    const { isIn24Hrs } = useFulfillmentHooks();
    const [guestUserId, setGuestUserId] = useCookieValue('guest_user_id');
    const sbmId = sessionStorage.getItem('sbmid');
    const sbsId = sessionStorage.getItem('sbsid');
    const [sbaidCookie, setsbaidCookie] = useCookieValue('sbaid');
    const [pos] = useCookieValue('pos');
    const [guestSessionID] = useCookieValue(STORAGE_CONFIG.COOKIES.GUESTSESSIONID);
    const time = moment();
    const sunbeltLocation = useSunbeltLocation();
    const authorityType = useCheckAuthorityType();
    const { fetchQuoteDetails } = useQuoteDetails();
    const isP2POrPunchout = [AUTHORITY_TYPE.P2P, AUTHORITY_TYPE.PUNCHOUT].includes(authorityType);
    const dukeCustomBillingFields = () => {
        let billingFields = `Work Order: ${customBillingFields?.wbsElement?.value}| Route Code: ${customBillingFields?.customerExtraField15?.value
            }| Code Block: ${customBillingFields?.customerExtraField16?.value}| 'Emergency Outage:'${customBillingFields?.customerExtraField17?.value ? ' "Y"' : ' "N"'
            }| ${userProfile?.firstName} ${userProfile?.lastName}| ${userProfile?.telephone}| ${projectDetails?.projectName
            }`;
        return billingFields;
    };
    const accountNumber = userAccount?.accountNumber;
    const corpLink = userProfile?.accounts?.find(account => account?.id === accountNumber)?.corplink;
    const getProjectInfoData = () => {
        return `${projectInfo?.primaryContactName} | ${projectInfo?.phoneNumber} | ${projectInfo?.accessNotes}`;
    };
    const createCreditDeliveryInstructions = () => {
        if (isInStorePickup) {
            return createPickupInstructionsHandler() || '';
        } else {
            if (corpLink === DUKE_CORP_LINK) {
                return dukeCustomBillingFields();
            } else {
                return getProjectInfoData();
            }
        }
    };

    const getProductPayloadForTransmitOrSave = (productData, itemsQuantity, productItems) => {
        try {
            const productsPayload = [];
            const rentalObj = consumables?.selected;
            let forceAddonsObj = {};
            productData.forEach(prod => {
                productsPayload.push({
                    productSKU: prod?.productId,
                    quantity: parseInt(itemsQuantity[prod?.productId]),
                    hourRate: parseFloat(prod?.rates?.suggestedRates?.minimum), //as per the request by Nachi and Vikrant i'm updating this change on 15/11/2023, need to check w/Gaurav,
                    minimumRate: parseFloat(prod?.rates?.suggestedRates?.minimum),
                    dayRate: parseFloat(prod?.rates?.suggestedRates?.daily),
                    weeklyRate: parseFloat(prod?.rates?.suggestedRates?.weekly),
                    monthlyRate: parseFloat(prod?.rates?.suggestedRates?.monthly),
                    ratesOverriden: false,
                    itemType: 'Equipment',
                    totalPrice: productItems[prod?.productId]?.totalPrice
                });
            });
            Object.keys(rentalObj).forEach(item => {
                let rentalObjItem = rentalObj[item];
                let selectedArray = Object.keys(rentalObjItem);
                if (selectedArray.includes('rentals')) {
                    let rentalArray = Object.values(rentalObjItem['rentals']);
                    rentalArray.forEach(itemRental => {
                        productsPayload.push({
                            productSKU: itemRental?.itemObj?.product?.sku,
                            quantity: itemRental?.qty,
                            hourRate: parseFloat(itemRental?.itemObj?.minRate),
                            minimumRate: parseFloat(itemRental?.itemObj?.minRate),
                            dayRate: parseFloat(itemRental?.itemObj?.dayRate),
                            weeklyRate: parseFloat(itemRental?.itemObj?.wkRate),
                            monthlyRate: parseFloat(itemRental?.itemObj?.moRate),
                            itemType: 'Accessory',
                            ratesOverriden: false,
                            totalPrice: itemRental?.qty * itemRental?.price,
                            forcedItem: Boolean(itemRental?.isForced)
                        });
                    });
                }
                if (selectedArray.includes('addons')) {
                    for (const [, value] of Object.entries(rentalObjItem['addons'])) {
                        if (value?.itemObj?.forceItem === FORCE_ITEM.YES) {
                            forceAddonsObj[`${value?.itemObj?.itemNumber}${value?.itemObj?.stockClass}`] = value;
                        }
                    }
                }
            });
            orderEstimates?.miscCharges?.forEach(prod => {
                const misPayloadObj = {
                    productSKU: prod?.productSku,
                    quantity: 1,
                    hourRate: 0,
                    minimumRate: 0,
                    dayRate: 0,
                    weeklyRate: 0,
                    monthlyRate: 0,
                    ratesOverriden: false,
                    miscChargeType: prod?.type,
                    miscCharge: prod?.charge?.toString(),
                    dailyBookRate: '',
                    lineItemType: '',
                    minimumBookRate: '',
                    miscChargesTax: prod?.tax?.toString(),
                    monthlyBookRate: '',
                    totalPrice: prod?.charge?.toString(),
                    weeklyBookRate: '',
                    itemType: 'MiscCharges',
                    unitPrice: prod?.charge?.toString()
                };
                //If the fulfillment type is PICKUP and the miscChargeType is DELIVERY or PICHUP, we will not add misPayloadObj in product array.
                if (isInStorePickup && (prod.type === 'DELIVERY' || prod.type === 'PICKUP')) {
                    return;
                }
                // skipping the product, if fuel charge is not checked
                if (prod.type === 'FUEL CONVENIENCE CHARGE') {
                    if (isFuelChargeChecked) {
                        productsPayload.push(misPayloadObj);
                    }
                } else {
                    productsPayload.push(misPayloadObj);
                }
            });
            orderEstimates?.itemizedCharges?.salesItems?.forEach(prod => {
                productsPayload.push({
                    itemNumber: prod?.itemNumber,
                    stockClass: prod?.stockClass,
                    unitPrice: prod?.unitPrice?.toString(),
                    quantity: prod?.quantity,
                    productSKU: prod?.productSku,
                    dailyBookRate: '',
                    lineItemType: '',
                    minimumBookRate: '',
                    miscChargesTax: '',
                    monthlyBookRate: '',
                    weeklyBookRate: '',
                    itemType: 'AddOn',
                    dayRate: 0,
                    hourRate: 0,
                    minimumRate: 0,
                    monthlyRate: 0,
                    weeklyRate: 0,
                    ratesOverriden: false,
                    totalPrice: prod?.totalPrice,
                    forcedItem: Boolean(forceAddonsObj[prod?.itemNumber + prod?.stockClass])
                });
            });
            return productsPayload;
        } catch (error) {
            logError(error, true, 'Failed to load product data');
        }
    };

    const getProducts = async (isTransmitOrSave = false) => {
        const itemsSKU = [];
        const itemsQuantity = {};
        const productsPayload = [];
        const productItems = {};
        let pcObj = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
        if (!pcObj?.pc) {
            pcObj = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SOURCEPCOBJ) || '{}');
        }
        cart?.availableCartItems?.forEach(prod => {
            itemsSKU.push({pc: selectedStoreDetails?.pc, productId: prod.product.sku});
            itemsQuantity[prod.product.sku] = prod.quantity;
            productItems[prod.product.sku] = {
                sku: prod?.product?.sku?.substring(0, 3),
                category_name: prod?.product?.category_name,
                thumbnail: { url: prod?.product?.thumbnail?.url },
                product_page_url: prod?.product?.product_page_url,
                superCategory: prod?.product?.categories?.[0]?.name,
                totalPrice: prod?.prices?.row_total?.value
            };
        });
        const customerNumberRates =
            userType === USER_TYPE.CREDIT ? userAccount?.accountNumber : viewCart?.customerNumber;
         const { data, error } = await getRatesByOwnedPc(
            customerNumberRates?.toString(),
            projectDetails?.selectedProjectJobId,
            itemsSKU
        );
        if (error) {
            return [];
        } else {
            if (isTransmitOrSave) {
                return getProductPayloadForTransmitOrSave(data.data.items, itemsQuantity, productItems);
            }
            data.data.items.forEach(prod => {
                productsPayload.push({
                    productId: prod?.productId,
                    lineSequence: '1',
                    quantity: itemsQuantity[prod?.productId],
                    freeFlag: 'N',
                    toolFlex: 'N',
                    hourRate: 0.0,
                    minimumRate: parseFloat(prod?.rates?.suggestedRates?.minimum),
                    dayRate: parseFloat(prod?.rates?.suggestedRates?.daily),
                    weeklyRate: parseFloat(prod?.rates?.suggestedRates?.weekly),
                    monthlyRate: parseFloat(prod?.rates?.suggestedRates?.monthly),
                    dayOver: 'N',
                    weekOver: 'N',
                    monthOver: 'N',
                    isSerialized: prod?.isSerialized === 'TRUE' ? true : false,
                    ...getAdditionalMarketoPayload(
                        productItems[prod?.productId],
                        productItems[prod?.productId]?.superCategory,
                        pcObj
                    )
                });
            });
            const rentalObj = consumables?.selected;
            Object.keys(rentalObj).forEach(item => {
                let selectedArray = Object.keys(rentalObj[item]);
                if (selectedArray.includes('rentals')) {
                    let key = Object.keys(rentalObj[item]['rentals']);
                    let rentalArray = Object.values(rentalObj[item]['rentals']);
                    rentalArray.forEach((itemRental, index) => {
                        productsPayload.push({
                            productId: key[index],
                            lineSequence: itemRental?.itemObj?.sequence,
                            quantity: itemRental?.qty,
                            freeFlag: parseFloat(itemRental?.itemObj?.minRate) == 0 ? 'Y' : 'N',
                            toolFlex: 'N',
                            hourRate: 0.0,
                            minimumRate: parseFloat(itemRental?.itemObj?.minRate),
                            dayRate: parseFloat(itemRental?.itemObj?.dayRate),
                            weeklyRate: parseFloat(itemRental?.itemObj?.wkRate),
                            monthlyRate: parseFloat(itemRental?.itemObj?.moRate),
                            dayOver: 'N',
                            weekOver: 'N',
                            monthOver: 'N',
                            isSerialized: itemRental?.itemObj?.IsSerialized === 'true' ? true : false,
                            ...getAdditionalMarketoPayload(
                                itemRental?.itemObj?.product,
                                itemRental?.itemObj?.product?.categories?.[0]?.name,
                                pcObj
                            )
                        });
                    });
                }
            });
            return productsPayload;
        }
    };
    const getAdditionalMarketoPayload = (product, superCategory, pcObj) => {
        let result = {};
        if (!punchoutUserData?.isPunchoutUser) {
            result = {
                categoryId: product?.sku?.substring(0, 3),
                categoryName: product?.category_name,
                specialtyId: String(pcObj?.specialtyTypes?.[0] || ''),
                productImageURL: product?.thumbnail?.url?.replace('fmt=webp', 'fmt=jpg'),
                superCategory,
                productURL: window?.location?.origin + product?.product_page_url
            };
        }
        return result;
    };
    const getStartDate = () => {
        let dateMoment = moment(startDate);
        let pickupTime = '';
        if (!isInStorePickup && howToGetYourOrderDetails.selectedDeliveryTime?.label) {
            const startDateTimeLocale = howToGetYourOrderDetails.selectedDeliveryTime.slot?.split(' - ')[1];
            const startDateLocale = startDateTimeLocale?.split('T')[0];
            const startTimeLocale = startDateTimeLocale?.split('T')[1];
            return convertDateToMomentDateTimeNonUTC(startDateLocale, startTimeLocale);
        } else {
            if (howToGetYourOrderDetails.selectedPickupTime) {
                pickupTime = moment(howToGetYourOrderDetails.selectedPickupTime, 'h:mm A');
            }
            return convertDateToMomentDateTimeNonUTC(startDate, pickupTime);
        }
    };
    const getEndDate = () => {
        let returnTime = '';
        if (!isInStorePickup) {
            if (howToGetYourOrderDetails.selectedPickUpTime?.label) {
                const endDateTimeLocale = howToGetYourOrderDetails.selectedPickUpTime.slot?.split(' - ')[0];
                const endDateLocale = endDateTimeLocale?.split('T')[0];
                const endTimeLocale = endDateTimeLocale?.split('T')[1];
                return convertDateToMomentDateTimeNonUTC(endDateLocale, endTimeLocale);
            } else {
                const startDateTimeLocale = howToGetYourOrderDetails.selectedDeliveryTime.slot?.split(' - ')[1];
                const startTimeLocale = startDateTimeLocale?.split('T')[1];
                return convertDateToMomentDateTimeNonUTC(endDate, startTimeLocale);
            }
        } else {
            if (howToGetYourOrderDetails.selectedReturnTime) {
                returnTime = moment(howToGetYourOrderDetails.selectedReturnTime, 'h:mm A');
            }
            return convertDateToMomentDateTimeNonUTC(endDate, returnTime);
        }
    };
    const deliveryCharge = () => {
        if (isInStorePickup) {
            return 0.0;
        } else {
            if (deliveryChargeFlag === DELIVERY_CHARGE_FLAG.SHOW_ESTIMATES) {
                const deliveryFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('DELIVERY') > -1);
                const { charge = 0 } = deliveryFeeObj || {};
                return parseFloat(charge);
            } else {
                return 0.0;
            }
        }
    };
    const pickupCharge = () => {
        if (isInStorePickup) {
            return 0.0;
        } else {
            if (deliveryChargeFlag === DELIVERY_CHARGE_FLAG.SHOW_ESTIMATES) {
                const pickupFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('PICKUP') > -1);
                const { charge = 0 } = pickupFeeObj || {};
                return parseFloat(charge);
            } else {
                return 0.0;
            }
        }
    };
    const getConfirmationMessage = () => {
        switch (checkReservationType()) {
            case 1:
                return {
                    type: VARIABLE_CONFIG.RESERVATION_TYPE.PRIMARY,
                    message: VARIABLE_CONFIG.RESERVATION_STATUS.SUCCESS_FULL_FULFILLMENT
                };
            case 2:
                return {
                    type: VARIABLE_CONFIG.RESERVATION_TYPE.SECONDARY,
                    message: VARIABLE_CONFIG.RESERVATION_STATUS.SUCCESS_PARTIAL_FULFILLMENT
                };
            case 3:
                return {
                    type: VARIABLE_CONFIG.RESERVATION_TYPE.TERTIARY,
                    message: VARIABLE_CONFIG.RESERVATION_STATUS.SUCCESS_NO_FULFILLMENT
                };
            default:
                return {
                    type: VARIABLE_CONFIG.RESERVATION_TYPE.TERTIARY,
                    message: VARIABLE_CONFIG.RESERVATION_STATUS.SUCCESS_NO_FULFILLMENT
                };
        }
    };
    const createPickupInstructionsHandler = () => {
        let pickupInstructions = '';
        const { firstname, lastname, phone, email } = howToGetYourOrderDetails?.someoneElse;
        if (firstname && lastname && phone && email) {
            pickupInstructions = `${firstname} ${lastname} | ${phone} | ${email}`;
        } else {
            if (userType === USER_TYPE.CREDIT) {
                pickupInstructions = getProjectInfoData();
            }
        }
        return pickupInstructions;
    };
    const createCashUserPayload = async () => {
        let customizeNumber = userProfile?.phone?.split(' ');
        const areaCode = customizeNumber?.[0]?.substring(1, 4);
        const phone = customizeNumber?.[1]?.replace('-', '');
        return {
            storeId: selectedStoreDetails?.pc,
            cart_id: cartId,
            companyId: localStorage.getItem('companyID') || '1',
            oktaId: userProfile?.oktaUserId,
            customerType: 'cash',
            driverLicenseState: userProfile?.dlState,
            jobNumber: '',
            driverLicenseNumber: userProfile?.dlNumber?.trim(),
            isDelivery: isInStorePickup ? false : true,
            deliveryInstructions: !isInStorePickup
                ? projectInfo?.accessNotes || ''
                : createPickupInstructionsHandler() || '',
            startDateTime: getStartDate(),
            endDateTime: getEndDate(),
            earliestDateTime: getStartDate(),
            optOutRPP: isRPPChecked ? 'N' : 'Y',
            optOutFuelCharge: isFuelChargeChecked ? 'N' : 'Y',
            deliveryCharge: deliveryCharge(),
            orderByEmail: userProfile?.email,
            areaCode: areaCode,
            phoneNumber: phone,
            sessionID: userProfile?.userGuid,
            tokenID: paymentData?.selectedPaymentDetails?.tokenID || '7761722530171111', //TODO
            tokenPvID: paymentData?.selectedPaymentDetails?.tokenPvID || '002', //TODO
            tokenExpirationDate: paymentData?.selectedPaymentDetails?.tokenExpirationDate || '0424', //TODO
            allocateAsset: isIn24Hrs(startDate, currentOffset),
            channel: isP2POrPunchout ? 'PUNCHOUT' : 'WEBAPP',
            address1: viewCart?.location?.split(',')[0],
            city: viewCart?.jobSiteCity,
            state: viewCart?.jobSiteState,
            zip: viewCart?.jobSiteZip,
            distance: getDistance(viewCart?.isInStorePickup, true),
            pickUpCharge: pickupCharge(),
            products: await getProducts(),
            salesItems: getSalesItem(),
            orderedBy: `${userProfile?.lastName}, ${userProfile?.firstName}`,
            sourceSystem: 'Web',
            sunbeltAnonymousId: handleAnonymousIdFormatting(sbaidCookie) || undefined,
            sunbeltSessionId: handleAnonymousIdFormatting(sbsId) || undefined,
            sunbeltMarketingId: handleAnonymousIdFormatting(sbmId) || undefined,
            ipAddress:
                isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.IP_ADDRESS)) ||
                ENV_CONFIG.IP_ADDRESS_DEFAULT,
            reservationConfirmationScreen: getConfirmationMessage().type,
            ...reservationMarketoPayload()
        };
    };
    const getSalesItem = () => {
        const itemObj = consumables?.selected;
        let newArray = [];
        Object.keys(itemObj).forEach(item => {
            let selectedArray = Object.keys(itemObj[item]);
            if (selectedArray.includes('addons')) {
                let purchaseArray = Object.values(itemObj[item]['addons']);
                purchaseArray.forEach(itemAddon => {
                    newArray.push({
                        itemNumber: itemAddon?.itemObj?.itemNumber,
                        quantity: itemAddon.qty,
                        unitPrice: itemAddon.price,
                        stockClass: itemAddon?.itemObj?.stockClass
                    });
                });
            }
        });
        return newArray;
    };
    const getAreaCodeAndPhone = phoneNumber => {
        let areaCode = '';
        let phone = '';
        if (phoneNumber?.includes('-')) {
            const customizeNumber = phoneNumber?.split('-');
            areaCode = customizeNumber?.[0];
            phone = `${customizeNumber?.[1]}${customizeNumber?.[2]}`;
        } else {
            areaCode = phoneNumber?.substring(0, 3);
            phone = phoneNumber?.substring(3, 10);
        }
        return { areaCode, phone };
    };
    const createCreditUserPayload = async () => {
        const { areaCode, phone } = getAreaCodeAndPhone(projectInfo?.phoneNumber || phoneNumber);
        const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
        return {
            driverLicenseState: '',
            driverLicenseNumber: '',
            tokenID: '',
            tokenPvID: '',
            distance: getDistance(viewCart?.isInStorePickup, true),
            cart_id: cartId,
            companyId: localStorage.getItem('companyID') || '1',
            storeId: isValidString(overridePC?.pc) ? overridePC?.pc : selectedStoreDetails?.pc,
            oktaId: userProfile?.oktaUserId,
            customerType: 'credit',
            customer: userAccount?.accountNumber,
            jobNumber: selectedProjectJobId,
            isDelivery: isInStorePickup ? false : true,
            deliveryInstructions: createCreditDeliveryInstructions(),
            startDateTime: getStartDate(),
            endDateTime: getEndDate(),
            earliestDateTime: getStartDate(),
            optOutRPP: isRPPChecked ? 'N' : 'Y',
            optOutFuelCharge: isFuelChargeChecked ? 'N' : 'Y',
            deliveryCharge: deliveryCharge(),
            orderByEmail: userProfile?.email,
            areaCode,
            sessionID: userProfile?.userGuid,
            tokenExpirationDate: '1121', //TODO
            allocateAsset: isIn24Hrs(startDate, currentOffset),
            channel: isP2POrPunchout ? 'PUNCHOUT' : 'WEBAPP',
            pickUpCharge: pickupCharge(),
            products: await getProducts(),
            orderedBy: `${userProfile?.lastName}, ${userProfile?.firstName}`,
            salesItems: getSalesItem(),
            sourceSystem: 'Web',
            phoneNumber: phone,
            address1: projectAddress1,
            city: selectedProjectCity,
            state: selectedProjectState,
            zip: selectedProjectZip,
            purchaseOrderNumber: projectInfo?.poNumber?.trim() ? '' + projectInfo?.poNumber?.trim() : '',
            sunbeltAnonymousId: handleAnonymousIdFormatting(sbaidCookie) || undefined,
            sunbeltSessionId: handleAnonymousIdFormatting(sbsId) || undefined,
            sunbeltMarketingId: handleAnonymousIdFormatting(sbmId) || undefined,
            reservationConfirmationScreen: getConfirmationMessage().type,
            ...reservationMarketoPayload()
        };
    };
    const getDLStateGuestUser = () => {
        if (isValidString(sessionStorage.getItem('dlStateGuestUser'))) {
            return sessionStorage.getItem('dlStateGuestUser');
        } else if (localStorage.getItem('companyID') == 2) {
            return 'WG';
        } else {
            return 'NC';
        }
    };
    const getDLNumberGuestUser = () => {
        if (isValidString(sessionStorage.getItem('dlNumberGuestUser'))) {
            return sessionStorage.getItem('dlNumberGuestUser');
        } else if (localStorage.getItem('companyID') == 2) {
            return '1018293';
        } else {
            return '123456';
        }
    };
    const createGuestUserPayload = async () => {
        const { areaCode, phone } = getAreaCodeAndPhone(guestUserDetails?.phoneNumber);
        return {
            storeId: selectedStoreDetails?.pc,
            cart_id: cartId,
            companyId: localStorage.getItem('companyID') || '1',
            oktaId: '', //TODO
            customerType: 'guest',
            driverLicenseState: getDLStateGuestUser(),
            driverLicenseNumber: getDLNumberGuestUser(),
            isDelivery: isInStorePickup ? false : true,
            deliveryInstructions: isInStorePickup ? createPickupInstructionsHandler() : '',
            startDateTime: getStartDate(),
            endDateTime: getEndDate(),
            earliestDateTime: getStartDate(),
            optOutRPP: isRPPChecked ? 'N' : 'Y',
            optOutFuelCharge: isFuelChargeChecked ? 'N' : 'Y',
            deliveryCharge: deliveryCharge(),
            orderByEmail: guestUserDetails?.email,
            areaCode: areaCode,
            phoneNumber: phone,
            sessionID: '', //TODO
            tokenID: paymentData?.selectedPaymentDetails?.tokenID || '7761722530171111', //TODO
            tokenPvID: paymentData?.selectedPaymentDetails?.tokenPvID || '002', //TODO,
            tokenZipCode: paymentTokenData?.acctAVS,
            tokenCardType: paymentTokenData?.ccBrand,
            tokenCardHolderName: paymentTokenData?.customerName,
            tokenLast4Digits: paymentTokenData?.acctLastFour?.slice(-4),
            tokenExpirationDate: paymentData?.selectedPaymentDetails?.tokenExpirationDate || '0424', //TODO
            allocateAsset: isIn24Hrs(startDate, currentOffset),
            channel: isP2POrPunchout ? 'PUNCHOUT' : 'WEBAPP',
            address1: viewCart?.location?.split(',')[0],
            city: viewCart?.jobSiteCity,
            state: viewCart?.jobSiteState,
            zip: viewCart?.jobSiteZip,
            distance: getDistance(viewCart?.isInStorePickup, true),
            pickUpCharge: pickupCharge(),
            products: await getProducts(),
            salesItems: getSalesItem(),
            orderedBy: `${guestUserDetails?.lastName}, ${guestUserDetails?.firstName}`,
            sourceSystem: 'Web',
            sunbeltAnonymousId: handleAnonymousIdFormatting(sbaidCookie) || undefined,
            sunbeltSessionId: handleAnonymousIdFormatting(sbsId) || undefined,
            sunbeltMarketingId: handleAnonymousIdFormatting(sbmId) || undefined,
            ipAddress:
                isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.IP_ADDRESS)) ||
                ENV_CONFIG.IP_ADDRESS_DEFAULT,
            repCustomerId: guestUserId,
            reservationConfirmationScreen: getConfirmationMessage().type,
            ...reservationMarketoPayload()
        };
    };
    const createGuestPayload = (submissionId, clientIP) => {
        const address = viewCart?.location?.split(',');
        return {
            address: [
                {
                    type: '',
                    city: viewCart?.jobSiteCity,
                    country: address[address.length - 1],
                    line1: address[0],
                    line2: viewCart?.jobSiteAddr2 || '',
                    line3: '',
                    postalCode: viewCart?.jobSiteZip,
                    state: viewCart?.jobSiteState
                }
            ],
            email: guestUserDetails?.email,
            phone: guestUserDetails?.phoneNumber?.replaceAll('-', ''),
            firstName: guestUserDetails?.firstName,
            lastName: guestUserDetails?.lastName,
            isPunchOutUser: false, //TODO
            channel: isP2POrPunchout ? 'PUNCHOUT' : 'WEBAPP',
            createDate: moment(new Date()).format(),
            appCatalogMode: 'B', //TODO
            userType: 'guest',
            dateOfBirth: '1990-01-01', //TODO
            activate: false, //TODO,
            clientIPAddress: clientIP || '',
            submissionId: submissionId || ''
        };
    };
    const createNonCreditPayload = password => {
        const address = viewCart?.location?.split(',');
        return {
            address: [
                {
                    type: '',
                    city: viewCart?.jobSiteCity,
                    country: address[address.length - 1],
                    line1: address[0],
                    line2: viewCart?.jobSiteAddr2 || '',
                    line3: '',
                    postalCode: viewCart?.jobSiteZip,
                    state: viewCart?.jobSiteState
                }
            ],
            driverLicenseNumber: getDLNumberGuestUser(),
            driverLicenseState: getDLStateGuestUser(),
            email: guestUserDetails?.email,
            phone: guestUserDetails?.phoneNumber?.replaceAll('-', ''),
            firstName: guestUserDetails?.firstName,
            lastName: guestUserDetails?.lastName,
            password: password,
            isPunchOutUser: false, //TODO
            channel: isP2POrPunchout ? 'PUNCHOUT' : 'WEBAPP',
            createDate: moment(new Date()).format(),
            appCatalogMode: 'B', //TODO
            userType: 'noncredit',
            dateOfBirth: '1990-01-01', //TODO
            activate: true, //TODO,
            submissionId: sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SUBMISSION_ID) || '',
            clientIPAddress: sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CLIENT_IP_ADDRESS) || ''
        };
    };
    const createDummyUser = async () => {
        const { submissionId, clientIP, error: submissionApiError } = await generateSubmissionId();

        if (!submissionApiError) {
            const payload = createGuestPayload(submissionId, clientIP);

            const { data, error } = await createDummyGuestAccount(payload);

            return { data, error };
        }

        return { data: {}, error: submissionApiError };
    };

    const generateSubmissionId = async () => {
        try {
            const responseclientIP = await getClientIp();
            let error = '';
            if (responseclientIP?.status === SUCCESS_CODE_200) {
                const clientIP = responseclientIP?.data?.ip;
                sessionStorage?.setItem(STORAGE_CONFIG.SESSION_STORAGE.CLIENT_IP_ADDRESS, clientIP);
                await refreshTokenCall();
                const payload = {
                    email: guestUserDetails?.email,
                    IsCredit: userType === USER_TYPE.CREDIT,
                    sessionID: guestSessionID,
                    clientIPAddress: clientIP,
                    referrer: window.location.href,
                    sourceApplication: SBRWEB,
                    isExistingProfile: false
                };
                const response = await getSubmissionId(payload);
                if (response?.status === SUCCESS_CODE_200) {
                    const submissionId = response?.data?.data?.SubmissionID;
                    sessionStorage?.setItem(STORAGE_CONFIG.SESSION_STORAGE.SUBMISSION_ID, submissionId);
                    error = response?.error;
                    return { submissionId: submissionId, clientIP, error };
                } else {
                    error = response?.error;
                    return { submissionId: '', clientIP, error };
                }
            } else {
                error = responseclientIP?.error;
                return { submissionId: '', clientIP: '', error };
            }
        } catch (error) {
            logError(error, false, 'generateSubmissionId');
            return { submissionId: '', clientIP: '', error: true };
        }
    };
    const createGuestUser = async password => {
        const payload = createNonCreditPayload(password);
        const { data, error } = await createGuestAccount(payload);
        return { data, error };
    };
    const checkReservationType = () => {
        let reservationtype = 3;
        if (viewCart.isInStorePickup) {
            // validations for instore pickup
            if (fulfillmentPercent === 1 && !isLastResortBranch) {
                if (isIn24Hrs(startDate, currentOffset)) {
                    reservationtype = 1;
                } else {
                    if (isOpenOnDateOut) {
                        dispatch({
                            type: SET_SUBMIT_RESERVATION,
                            key: 'confirmationType',
                            value: 1
                        });
                        reservationtype = 1;
                    }
                }
            }
            if (
                fulfillmentPercent === 0 &&
                isLastResortBranch &&
                !isIn24Hrs(startDate, currentOffset) &&
                isOpenOnDateOut
            ) {
                reservationtype = 3;
            }
            if (
                !isIn24Hrs(startDate, currentOffset) &&
                !isLastResortBranch &&
                isOpenOnDateOut &&
                fulfillmentPercent >= 0 &&
                fulfillmentPercent < 1
            ) {
                reservationtype = 2;
            }
        } else {
            // validations for round trip delivery
            if (fulfillmentPercent === 1 && !isLastResortBranch) {
                reservationtype = 1;
            } else if (
                !isIn24Hrs(startDate, currentOffset) &&
                fulfillmentPercent > 0 &&
                fulfillmentPercent < 1 &&
                !isLastResortBranch
            ) {
                reservationtype = 2;
            } else if (!isIn24Hrs(startDate, currentOffset) && !fulfillmentPercent && isLastResortBranch) {
                reservationtype = 3;
            }
            let overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
            if (overridePC?.pc) {
                reservationtype = 1;
            }
            if (tbdModal?.isVisited) {
                if (reservationtype == 1) {
                    dispatch({ type: SET_SUBMIT_RESERVATION, key: 'confirmationType', value: 2 });
                    return reservationtype;
                }
            }
        }
        dispatch({ type: SET_SUBMIT_RESERVATION, key: 'confirmationType', value: reservationtype });
        return reservationtype;
    };
    const reservationMarketoPayload = () => {
        return {
            payMethod:
                userType !== USER_TYPE.CREDIT
                    ? cardTypeMapper(paymentData?.selectedPaymentDetails?.paymentType)?.toLowerCase() ||
                    paymentTokenData?.ccBrand
                    : VARIABLE_CONFIG.ACCOUNT_PAYMENT_TYPE.CREDIT,
            reservationDateTime: moment().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            sunbeltLocation: sunbeltLocation,
            cartCreatedAt: cart?.created_at,
            cartUpdatedAt: cart?.updated_at,
            NoDeliveryFee: deliveryChargeFlag === DELIVERY_CHARGE_FLAG.TBD ? true : false
        };
    };
    const submitReservation = async () => {
        let payload = {};
        const accountNumber = userAccount?.accountNumber;
        const corpLink = userProfile?.accounts?.find(account => account?.id === accountNumber)?.corplink;
        if (userType === USER_TYPE.CASH) {
            payload = await createCashUserPayload();
        } else if (userType === USER_TYPE.CREDIT) {
            let customBillingFieldObject = makeArrayFromObjects(customBillingFields);
            let creditUserPayload = await createCreditUserPayload();
            if (customBillingFieldObject?.length > 0 && corpLink !== DUKE_CORP_LINK) {
                creditUserPayload.customBillingFields = customBillingFieldObject;
            }
            payload = creditUserPayload;
        } else {
            payload = await createGuestUserPayload();
        }
        const { products } = payload || [];
        if (products?.length <= 0) {
            logError(error, true, 'submitReservation line 699', [products]);
            return;
        }
        const { data, error } = await createReservationProcess({
            createReservationMutation,
            payload,
            dispatch
        });
        if (error) {
            logError(error, false, 'submitReservation');
        }
        return { payload, data, error };
    };
    const createPunchoutUserPayload = async () => {
        let customizeNumber =
            projectInfo?.phoneNumber?.replace(/[- )(]/g, '') || phoneNumber?.replace(/[- )(]/g, '') || '';
        const areaCode = customizeNumber?.slice(0, 3) || '';
        const phone = customizeNumber?.slice(3, 10) || '';
        return {
            cart_id: cartId,
            storeId: selectedStoreDetails?.pc,
            customerType: 'po2go',
            driverLicenseState: userProfile?.dlState || '',
            driverLicenseNumber: userProfile?.dlNumber || '',
            isDelivery: isInStorePickup ? false : true,
            deliveryInstructions: createCreditDeliveryInstructions(),
            startDateTime: getStartDate(),
            endDateTime: getEndDate(),
            earliestDateTime: getStartDate(),
            optOutRPP: isRPPChecked ? 'N' : 'Y',
            optOutFuelCharge: isFuelChargeChecked ? 'N' : 'Y',
            deliveryCharge: deliveryCharge(),
            orderByEmail: userProfile?.email,
            areaCode: areaCode,
            phoneNumber: phone,
            sessionID: userProfile?.userGuid,
            tokenID: paymentData?.selectedPaymentDetails?.tokenID || '7761722530171111', //TODO
            tokenPvID: paymentData?.selectedPaymentDetails?.tokenPvID || '002', //TODO
            tokenExpirationDate: paymentData?.selectedPaymentDetails?.tokenExpirationDate || '0424', //TODO
            allocateAsset: isIn24Hrs(startDate, currentOffset),
            channel: isP2POrPunchout ? 'PUNCHOUT' : 'WEBAPP',
            address1: viewCart?.location || projectAddress1,
            city: viewCart?.jobSiteCity || selectedProjectCity,
            state: viewCart?.jobSiteState || selectedProjectState,
            zip: viewCart?.jobSiteZip || selectedProjectZip,
            distance: getDistance(viewCart?.isInStorePickup, true),
            pickUpCharge: pickupCharge(),
            orderedBy: `${userProfile?.lastName}, ${userProfile?.firstName}`,
            sourceSystem: 'SBRPunchout',
            salesItems: getSalesItem(),
            products: await getProducts(),
            companyId: localStorage.getItem('companyID') || '1',
            jobNumber: selectedProjectJobId,
            customer: userAccount?.accountNumber
        };
    };
    const createPunchoutOrder = async () => {
        let payload = await createPunchoutUserPayload();
        const { data, error } = await createPunchoutProcess({
            createPunchoutOrderMutation,
            payload,
            dispatch
        });
        return { data, error };
    };

    const isAnySpecialityItem = () => {
        return (
            cart?.items?.some(prod => {
                return (
                    calcRQFlagStatus(prod?.product?.showonlinecatalog) &&
                    calcRQFlagStatus(prod?.product?.disableaddtocartoption)
                );
            }) ?? false
        );
    };

    const createOrTransmitQuotePayload = async (isEditQuote = false) => {
        const {
            checkoutSubtotal,
            rentalSubtotal,
            purchaseSubtotal,
            deliveryCharges,
            pickupCharges,
            deliveryPickUpCharges,
            rentalProtectionPlan,
            prepayFuelOption,
            environmentalServiceFee,
            otherFees,
            taxes,
            allOtherCharges,
            estimatedSubtotal
        } = orderSummaryDetails;
        try {
            let payload = {
                cart_id: cartId,
                companyId: `0${localStorage.getItem('companyID') || '01'}`,
                branchNumber: selectedStoreDetails?.pc,
                accountNumber: userAccount?.accountNumber,
                fulfillmentType: isInStorePickup ? FULFILLMENT_TYPE_PICKUP : FULFILLMENT_TYPE_DELIVERY,
                deliveryInstructions: createCreditDeliveryInstructions(),
                startDateTime: getStartDate(),
                endDateTime: getEndDate(),
                earliestDateTime: getStartDate(),
                optOutRPP: !isRPPChecked,
                optOutFuelCharge: isFuelChargeChecked ? 'N' : 'Y',
                sellingChannel: isP2POrPunchout ? 'PUNCHOUT' : 'WEBAPP', //TODO - yet to get confirmation from Vikrant
                initiatingChannel: isP2POrPunchout ? 'PUNCHOUT' : 'WEBAPP',
                transactionType: 'X',
                type: 'P2P_Punchout',
                poNumber: poNumber,
                ratesOverriden: false,
                initiateTransmit: selectedTransmitOrSave === TRANSMIT_NOW,
                orderedBy: {
                    firstName: `${userProfile?.firstName}`,
                    lastName: `${userProfile?.lastName}`,
                    email: `${userProfile?.email}`,
                    phoneNumber: `${userProfile?.telephone}`
                },
                jobSiteId: selectedRMJobId?.trim(), // this RM id is coming with extra spaces at the last from API so need to trim down
                jobsiteNumber: selectedProjectJobId,
                hasSpecialityItem: isAnySpecialityItem(),
                products: await getProducts(true),
                pickUpCharge: pickupCharge(),
                deliveryCharge: deliveryCharge(),
                rentalSubTotal: rentalSubtotal,
                purchasesSubTotal: purchaseSubtotal,
                rppFees: rentalProtectionPlan,
                otherFees: otherFees,
                taxes: taxes,
                total: estimatedSubtotal,
                fuelCharge: prepayFuelOption,
                allOtherCharge: allOtherCharges,
                // tcsessionId: 'IT65575eea38112' //pos for local development
                tcsessionId: pos
            };
            if (isEditQuote) {
                payload = {
                    ...payload,
                    sfGuiQuoteId: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.TRANSMITTED_QUOTE_ID) || '',
                    sfQuoteId: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID) || '',
                    deletedproducts: await getDeletedProductsForQuote(
                        localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.TRANSMITTED_QUOTE_ID)
                    )
                };
            }
            return payload;
        } catch (error) {
            logError(error, true, 'createQuote API payload failed to load data');
        }
    };

    const getDeletedProductsForQuote = async quoteId => {
        try {
            const { data } = await fetchQuoteDetails({
                sfGuiQuoteId: quoteId
            });
            if (data?.viewQuote?.status === 200) {
                const productData = data?.viewQuote?.data?.products || [];
                return productData.map(item => ({ sfLineItemId: item?.sfLineItemId }));
            }
            return [];
        } catch (error) {
            logError(error, true, 'getDeletedProductsForQuote API payload failed while fetching viewQuotes');
        }
    };

    const createOrTransmitQuote = async () => {
        try {
            let payload = await createOrTransmitQuotePayload();
            const { data, error } = await createOrTransmitQuotePayloadProcess({
                createOrTransmitQuoteMutation,
                payload,
                dispatch
            });
            return { data, error };
        } catch (error) {
            logError(error, true, 'createQuote API failed to load data');
        }
    };

    const editOrSaveQuote = async () => {
        try {
            let payload = await createOrTransmitQuotePayload(true);
            const { data, error } = await editOrSaveQuotePayloadProcess({
                editOrSaveQuoteMutation,
                payload,
                dispatch
            });
            return { data, error };
        } catch (error) {
            logError(error, true, 'editOrSaveQuote API failed');
        }
    };

    const getSupplierAuxId = (orderId, productId, lineItemsObj) => {
        return `${localStorage.getItem('companyID')}/${userAccount?.accountNumber}/${orderId}/${lineItemsObj ? lineItemsObj[productId]?.lineItemType : ''
            }/${lineItemsObj ? lineItemsObj[productId]?.lineItemNumber : ''}`; // companyID/account/contract/linetype/line
    };
    const createItemsForPunchout = async (orderId, productLineItems) => {
        const itemsSKU = [];
        const itemsQuantity = {};
        const productsPayload = [];
        const unitPriceObj = {};
        const productName = {};
        cart?.availableCartItems?.forEach(prod => {
            itemsSKU.push({pc: selectedStoreDetails?.pc, productId: prod.product.sku});
            itemsQuantity[prod.product.sku] = prod.quantity;
            productName[prod.product.sku] = prod.product.name;
        });
        const { data, error } = await getRatesByOwnedPc(
            userAccount?.accountNumber?.toString(),
            projectDetails?.selectedProjectJobId,
            itemsSKU
        );
        orderEstimates?.itemizedCharges?.products.forEach(product => {
            unitPriceObj[generateSKU(product?.catId, product?.classId)] = product?.unitRentalCost;
        });
        if (error) {
            return [];
        } else {
            data?.data?.items?.forEach(prod => {
                productsPayload.push({
                    companyid: parseInt(localStorage.getItem('companyID')) || 1,
                    supplierid: prod?.productId,
                    supplierauxid: getSupplierAuxId(orderId, prod?.productId, productLineItems),
                    contractline: productLineItems ? productLineItems[prod?.productId]?.lineItemNumber : '',
                    quantity: itemsQuantity[prod?.productId],
                    unitprice: unitPriceObj[prod?.productId],
                    currency: localStorage.getItem('companyID') == 2 ? 'CAD' : 'USD',
                    classification: null,
                    classdomain: null,
                    uom: 'EA', // TO DO
                    description: productName[prod?.productId],
                    language: null,
                    orderedby: ' ',
                    requesteddate: getStartDate(),
                    estreturndate: getEndDate(),
                    orderdate: moment(new Date()).format(),
                    category: prod?.catId ? parseInt(prod?.catId) : '',
                    class: prod?.classId ? parseInt(prod?.classId) : '',
                    webclassid: null,
                    supercatwebclassid: null,
                    productcatwebclassid: null,
                    minrate: parseFloat(prod?.rates?.suggestedRates?.minimum),
                    dayrate: parseFloat(prod?.rates?.suggestedRates?.daily),
                    weekrate: parseFloat(prod?.rates?.suggestedRates?.weekly),
                    monthrate: parseFloat(prod?.rates?.suggestedRates?.monthly)
                });
            });
            const rentalObj = consumables?.selected;
            Object.keys(rentalObj).forEach(item => {
                let selectedArray = Object.keys(rentalObj[item]);
                if (selectedArray.includes('rentals')) {
                    let key = Object.keys(rentalObj[item]['rentals']);
                    let rentalArray = Object.values(rentalObj[item]['rentals']);
                    rentalArray.forEach((itemRental, index) => {
                        productsPayload.push({
                            companyid: localStorage.getItem('companyID')
                                ? parseInt(localStorage.getItem('companyID'))
                                : 1,
                            supplierid: key[index],
                            supplierauxid: getSupplierAuxId(orderId, key[index], productLineItems),
                            contractline: productLineItems ? productLineItems[key[index]]?.lineItemNumber : '',
                            quantity: itemRental?.qty,
                            unitprice: unitPriceObj[key[index]],
                            currency: localStorage.getItem('companyID') == 2 ? 'CAD' : 'USD',
                            classification: null,
                            classdomain: null,
                            uom: 'EA', // TO DO
                            description: itemRental?.itemObj?.description,
                            language: null,
                            orderedby: ' ',
                            requesteddate: getStartDate(),
                            estreturndate: getEndDate(),
                            orderdate: moment(new Date()).format(),
                            category: itemRental?.itemObj?.rentalCat,
                            class: itemRental?.itemObj?.rentalClass,
                            webclassid: null,
                            supercatwebclassid: null,
                            productcatwebclassid: null,
                            minrate: parseFloat(itemRental?.itemObj?.minRate),
                            dayrate: parseFloat(itemRental?.itemObj?.dayRate),
                            weekrate: parseFloat(itemRental?.itemObj?.wkRate),
                            monthrate: parseFloat(itemRental?.itemObj?.moRate)
                        });
                    });
                }
            });
        }
        return productsPayload;
    };
    const getMiscCharges = (orderId, otherLineItems = []) => {
        return otherLineItems?.flatMap(item => {
            const lineObj = {};
            lineObj[item?.description] = { ...item };
            if (item?.description != RENTAL_PROTECTION_PLAN) {
                return {
                    description: item?.description,
                    cost: item?.cost,
                    companyid: parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                    supplierid: item?.description || '', // TO DO
                    supplierauxid: getSupplierAuxId(orderId, item?.description, lineObj),
                    contractline: item?.lineItemNumber || ''
                };
            }
            return [];
        });
    };
    const postPunchoutOrderPayload = async (orderId, productLineItems, otherLineItems) => {
        return {
            edit_mode: 0,
            accountnumber: userAccount?.accountNumber,
            contractnumber: orderId ? parseInt(orderId) : '',
            ponumber: '',
            jobnumber: selectedProjectJobId,
            total: estimatedTotal ? parseFloat(estimatedTotal) : '',
            currency: localStorage.getItem('companyID') == 2 ? 'CAD' : 'USD',
            items: await createItemsForPunchout(orderId, productLineItems),
            additionalcharges: getMiscCharges(orderId, otherLineItems),
            jobsite: {
                name: projectName,
                address1: projectAddress1,
                address2: projectAddress2,
                city: selectedProjectCity,
                state: selectedProjectState,
                zip: selectedProjectZip
            },
            profitcenter: {
                storeId: selectedStoreDetails?.pc,
                name: selectedStoreDetails?.name,
                address1: selectedStoreDetails?.street,
                address2: null,
                city: selectedStoreDetails?.city,
                state: selectedStoreDetails?.state,
                zip: selectedStoreDetails?.zip
            },
            additionalemails: null, // TO DO
            comments: ':Limited Availability. Confirm with customer.', // TO DO
            customdata: [] // TO DO
        };
    };

    /** This function will reset some details from localStorage, sessionStorage, cookie after success */
    const resetDetailsAfterQuoteSaveOrTransmit = () => {
        const { loginToken, accessToken } = JSON.parse(
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.USER_LOGIN) || '{}'
        );
        clearLocalStorage([
            STORAGE_CONFIG.LOCAL_STORAGE.FIRSTNAME,
            STORAGE_CONFIG.LOCAL_STORAGE.TRANSMITTED_QUOTE_ID,
            STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID,
            STORAGE_CONFIG.LOCAL_STORAGE.CORP_ACCOUNT_DETAILS
        ]);
        const user = { loginToken, accessToken };
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.USER_LOGIN, JSON.stringify(user));
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY, 0);
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.STARTDATE);
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.ENDDATE);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.SHOWEDITQUOTEBANNER, false);
        // Once quote is being transmitted or saved, will set localAssetsData as empty array
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.LOCAL_ASSETS_DATA, '[]');
        dispatch({ type: 'reset' });
    };

    return [
        {
            submitReservation,
            createDummyUser,
            createGuestUser,
            createPunchoutOrder,
            createOrTransmitQuote,
            editOrSaveQuote,
            createGuestUserPayload,
            getConfirmationMessage,
            createCashUserPayload,
            createCreditUserPayload,
            postPunchoutOrderPayload,
            getStartDate,
            getEndDate,
            resetDetailsAfterQuoteSaveOrTransmit
        }
    ];
};
export default useReservation;
