import moment from 'moment';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
    SET_CURRENT_OFFSET_VALUE,
    SET_END_DATE_SLOTS,
    SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
    SET_LOCATION_DATA,
    SET_START_DATE_SLOTS,
    SET_TIMEZONE_ID,
    SET_VIEW_CART_FIELDS
} from '../aem-core-components/actions/constants';
import { useCartState } from '../contexts/cart';
import useCartEstimate from '../components/App/hooks/useCartEstimate';
import { useUserContext } from '../aem-core-components/context/UserContext';
import {
    getAVSValidationAPI,
    getDateSlotAvailability,
    getTimezoneAPI
} from '../components/checkoutv2/checkoutAndOrderSummary/api/getCheckoutAPIs';
import { getStores } from '../components/checkoutv2/utils/editOrderDetailsFunctions';
import useFulfillmentHooks from '../components/checkoutv2/utils/useFulFillmentHooks';
import { getTimeFromCity } from '../components/global/utils/commonUtils';
import { useAnalyticsContext } from '../config/GoogleTagManagerEvents';
import { HOW_TO_GET_YOUR_ORDER, VIEW_CART } from '../constants/cartConstants';
import { VARIABLE_CONFIG } from '../constants/analyticsConstants/Variables';
import { ENV_CONFIG } from '../constants/envConfig';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { useFilterState } from '../components/cap';
import { USER_TYPE } from '../constants/userDetailsConstants';
import { useCheckUser } from './useCheckUser';
import useUserData from './useUserData';

const useRoundTrip = () => {
    const [{ cart, howToGetYourOrderDetails }, dispatch] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate }, filterDispatch] = useFilterState();
    const viewCartFromStorage = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART)
        ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.VIEWCART))
        : viewCart;

    const intl = useIntl();
    const COMPANY_ID = parseInt(localStorage.getItem('companyID')) || 1;
    const [currentOffSet, setCurrentOffSet] = useState('');
    const { isInStorePickup } = viewCart;
    const [suggestedAddressList, setSuggestedAddressList] = useState([]);
    const [avsError, setAvsError] = useState('');
    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');
    const [{ getEstimates }] = useCartEstimate();
    const { isIn24Hrs, isPCfulfillmentVoid } = useFulfillmentHooks();
    const [userState] = useUserContext();
    const { userProfile } = userState;
    const userType = useCheckUser();
    const [{ getAssets, updateGeography }] = useUserData();
    const { sendEventsForError } = useAnalyticsContext();
    const [canadaInvalidStateError, setCanadaInvalidStateError] = useState('');
    const validStateList = ENV_CONFIG.VALID_STATE_LIST;
    const location = cart?.location;

    const getEquipentsList = () => {
        let items = cart?.availableCartItems;
        let equipments = [];
        items?.forEach((item, index) => {
            const { sku = '' } = item?.product;
            const quantity = item?.quantity;
            equipments.push({
                productId: sku,
                quantity: quantity,
                sequenceNumber: index
            });
        });
        return equipments;
    };

    const isLargeEquipment = () => {
        let isEquipmentLarge = false;
        cart?.availableCartItems?.every(item => {
            if (item?.product?.isOverSize == 'Yes' || item?.product?.isOverWeight == 'Yes') {
                isEquipmentLarge = true;
            }
        });
        return isEquipmentLarge;
    };

    const dateSlotApi = async (addressObj, offset, handleStepChange, currentPC, checkoutErrorHandlingAnalytics) => {
        dispatch({ type: 'beginLoading' });
        const [startDateSlots, endDateSlots] = await Promise.all([
            getDateSlotAvailability(
                COMPANY_ID,
                addressObj?.lat,
                addressObj?.long,
                startDate,
                getEquipentsList(),
                isInStorePickup ? false : true,
                addressObj.jobSiteZip,
                isLargeEquipment(),
                offset || currentOffSet,
                currentPC,
                isIn24Hrs(startDate, offset)
            ),
            getDateSlotAvailability(
                COMPANY_ID,
                addressObj?.lat,
                addressObj?.long,
                endDate,
                getEquipentsList(),
                isInStorePickup ? false : true,
                addressObj.jobSiteZip,
                isLargeEquipment(),
                offset || currentOffSet,
                currentPC,
                isIn24Hrs(endDate, offset)
            )
        ]);

        dispatch({ type: SET_START_DATE_SLOTS, windowsResponse: startDateSlots });
        let startDateWindows = startDateSlots?.data?.data?.windows;
        let filteredStartDateSlots = startDateWindows?.filter(
            window => moment(window.start).format('MMM DD, YYYY') == moment(startDate).format('MMM DD, YYYY')
        );
        if (!filteredStartDateSlots?.length) {
            setStartDateError(intl.formatMessage({ id: 'checkout:no-delivery-slots-available' }));
            setAvsError(intl.formatMessage({ id: 'checkout:delivery-unavailable-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'checkout:delivery-unavailable-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.START_DATE
            );
            dispatch({ type: 'endLoading' });
            return;
        }

        dispatch({ type: SET_END_DATE_SLOTS, windowsResponse: endDateSlots });
        let endDateWindows = endDateSlots?.data?.data?.windows;
        let filteredEndDateSlots = endDateWindows?.filter(
            window => moment(window.start).format('MMM DD, YYYY') == moment(endDate).format('MMM DD, YYYY')
        );
        if (!filteredEndDateSlots?.length) {
            setEndDateError(intl.formatMessage({ id: 'checkout:no-pickup-slots-available' }));
            setAvsError(intl.formatMessage({ id: 'checkout-pickup-unavailable-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'checkout-pickup-unavailable-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.END_DATE
            );
            dispatch({ type: 'endLoading' });
            return;
        }
        setAvsError('');
        handleStepChange(2);
        if (userType !== USER_TYPE.GUEST) {
            checkPcAvailablity(currentPC, addressObj?.location);
        }
        dispatch({ type: 'endLoading' });
    };

    const checkPcAvailablity = (pc, addr1) => {
        let overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
        if (!overridePC?.pc) {
            const checkIfPcAvailable = async () => {
                if (cart?.availableCartItems?.length > 0) {
                    const { offset } = await fetchTimeZoneAPI(addr1);
                    const currStoresData = await getStores(
                        viewCartFromStorage,
                        startDate,
                        endDate,
                        projectDetails,
                        userType,
                        dispatch,
                        assets,
                        howToGetYourOrderDetails,
                        true,
                        isIn24Hrs(startDate, offset)
                    );
                    if (!currStoresdata?.[currStoresData?.deliveryStoreIndex]?.pc) {
                        filterDispatch({
                            type: SET_VIEW_CART_FIELDS,
                            key: VIEW_CART.SHOW_INACTIVE_PC_ALERT,
                            value: true
                        });
                    }
                } else {
                    clearInterval(viewCart?.checkPCInterval);
                }
            };
            clearInterval(viewCart?.checkPCInterval);
            const interval = setInterval(checkIfPcAvailable, 300000);
            filterDispatch({ type: SET_VIEW_CART_FIELDS, key: VIEW_CART.CHECK_PC_INTERVAL, value: interval });
        } else {
            clearInterval(viewCart?.checkPCInterval);
        }
    };

    const getAvsResponse = async (addressObj, isAddrListReq = false) => {
        setSuggestedAddressList([]);
        const avsResp = await getAVSValidationAPI(addressObj);
        const { data, avsSuggestedResponse, error } = avsResp;
        let isValid = false;
        let option = data ? data?.data?.addressInfo?.addrResult : avsSuggestedResponse?.data?.addressInfo?.addrResult;
        let latFromAVS = '';
        let longFromAVS = '';
        let addressFromAvs = '';
        let addrResult = 0;
        let avsError = '';
        switch (option) {
            case '0':
                isValid = true;
                addressFromAvs = data?.data?.addrList[0];
                latFromAVS = data?.data?.addressInfo?.lat;
                longFromAVS = data?.data?.addressInfo?.long;
                break;
            case '1':
                isValid = true;
                addressFromAvs = data?.data?.addrList[0];
                latFromAVS = data?.data?.addressInfo?.lat;
                longFromAVS = data?.data?.addressInfo?.long;
                break;
            case '2':
                setAvsError(data?.data?.addressInfo?.addMessage);
                avsError = data?.data?.addressInfo?.addMessage;
                dispatch({ type: 'endLoading' });
                break;
            case '3':
                addrResult = 3;
                addressFromAvs = isAddrListReq ? data?.data?.addrList : '';
                setSuggestedAddressList(data?.data?.addrList);
                dispatch({ type: 'endLoading' });
                break;
            case '4':
                setAvsError(intl.formatMessage({ id: 'order-details:avs-error-label' }));
                avsError = intl.formatMessage({ id: 'order-details:avs-error-label' });
                dispatch({ type: 'endLoading' });
                break;
            default:
                setAvsError(intl.formatMessage({ id: 'order-details:avs-error-label' }));
                avsError = intl.formatMessage({ id: 'order-details:avs-error-label' });
                dispatch({ type: 'endLoading' });
        }
        return { isValid, latFromAVS, longFromAVS, addressFromAvs, addrResult, avsError, error };
    };

    async function fetchTimeZoneAPI(address) {
        let offset = '';
        const responseData = await getTimezoneAPI(address);
        if (!responseData.error) {
            offset = getTimeFromCity(responseData?.data.data.timeZoneId);
            dispatch({
                type: SET_TIMEZONE_ID,
                timeZoneID: responseData?.data.data.timeZoneId
            });
            setCurrentOffSet(offset);
            dispatch({
                type: SET_CURRENT_OFFSET_VALUE,
                currentOffset: offset
            });
        } else {
            dispatch({ type: 'endLoading' });
        }
        return { offset, timeZoneID: responseData?.data.data.timeZoneId, error: responseData.error };
    }

    const getCountryLabel = () => {
        return localStorage.getItem('companyID') == 2
            ? intl.formatMessage({ id: 'common:country-ca' })
            : intl.formatMessage({ id: 'common:country-usa' });
    };

    const computeAvsAddressLine1 = addressFromAvs => {
        let location =
            `${addressFromAvs.address1 ? addressFromAvs.address1 + ', ' : ''}` +
            `${addressFromAvs.city ? addressFromAvs.city + ', ' : ''}` +
            `${addressFromAvs.state ? addressFromAvs.state : ''}` +
            `, ${getCountryLabel()}`;
        return location;
    };

    const isCanadaStateValid = state => {
        if (parseInt(localStorage.getItem('companyID')) == 2) {
            if (validStateList?.includes(state)) {
                setCanadaInvalidStateError('');
                return true;
            } else {
                setCanadaInvalidStateError(intl.formatMessage({ id: 'error:canada-invalid-state' }));
                return false;
            }
        }
        return true;
    };

    const isNewAddressDifferent = localLocationObj => {
        if (location?.jobSiteAddr1 != localLocationObj?.location) {
            return true;
        } else if (location?.jobSiteCity != localLocationObj?.jobSiteCity) {
            return true;
        } else if (location?.jobSiteState != localLocationObj?.jobSiteState) {
            return true;
        } else if (location?.jobSiteZip != localLocationObj?.jobSiteZip) {
            return true;
        } else if (location?.jobSiteAddr2 != localLocationObj?.jobSiteAddr2) {
            return true;
        } else if (location?.long != localLocationObj?.long) {
            return true;
        }
        return false;
    };

    const checkAndSetAddressChange = (newValue, currentValue) => {
        if (newValue?.toLowerCase() !== currentValue?.toLowerCase()) {
            return true;
        }
        return false;
    };

    const handleRTApis = async (
        localLocation,
        handleStepChange,
        isManualAddress,
        setIsManualAddress,
        addressChangedByGeocode,
        setAddressChangedByGeocode,
        checkoutErrorHandlingAnalytics
    ) => {
        dispatch({ type: 'beginLoading' });
        let localLocationObj = {
            location: '',
            lat: '',
            long: '',
            jobSiteAddr2: '',
            jobSiteCity: '',
            jobSiteState: '',
            jobSiteZip: '',
            pc: '',
            pcLat: '',
            pcLong: ''
        };
        let offset = '';

        let isStateValid = isCanadaStateValid(localLocation?.selectedState);
        if (!isStateValid) {
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'checkout:invalid-address-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.ADDRESS
            );
            dispatch({ type: 'endLoading' });
            return { address: '', error: true };
        }
        let isValid = true;
        let latFromAVS = '';
        let longFromAVS = '';
        let addressFromAvs = '';
        if (!(!isManualAddress && viewCart?.jobSiteZip?.substring(-3) === '000') && !addressChangedByGeocode) {
            ({ isValid, latFromAVS, longFromAVS, addressFromAvs } = await getAvsResponse(localLocation));

            if (!isValid) {
                setAddressChangedByGeocode(false);
                dispatch({ type: 'endLoading' });
                return { address: '', error: true };
            } else {
                if (
                    checkAndSetAddressChange(addressFromAvs?.address1, localLocation?.streetAddress) ||
                    checkAndSetAddressChange(addressFromAvs?.city, localLocation?.selectedCity) ||
                    checkAndSetAddressChange(addressFromAvs?.state, localLocation?.selectedState) ||
                    checkAndSetAddressChange(addressFromAvs?.zip, localLocation?.selectedZip)
                ) {
                    filterDispatch({
                        type: SET_LOCATION_DATA,
                        location: addressFromAvs?.address1 || '',
                        jobSiteAddr2: localLocation?.address2 || '',
                        jobSiteCity: addressFromAvs?.city || '',
                        jobSiteState: addressFromAvs?.state || '',
                        jobSiteZip: addressFromAvs?.zip || '',
                        lat: latFromAVS,
                        long: longFromAVS
                    });
                    setAddressChangedByGeocode(true);
                    setIsManualAddress(false);
                    dispatch({ type: 'endLoading' });

                    return { address: addressFromAvs?.address1, error: false };
                } else {
                    setAddressChangedByGeocode(false);
                }
            }
        } else {
            if (addressChangedByGeocode) {
                if (!avsError) {
                    setAddressChangedByGeocode(false);
                }
            }
        }

        if (!isValid) {
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'checkout:invalid-address-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.ADDRESS
            );
            dispatch({ type: 'endLoading' });
            return isValid;
        }

        if (localLocation.streetAddress || addressFromAvs?.address1) {
            const address = localLocation
                ? `${localLocation?.streetAddress}, ${localLocation?.selectedCity}, ${localLocation?.selectedState}`
                : '';
            const locationavsAddress = addressFromAvs
                ? `${addressFromAvs?.address1}, ${addressFromAvs?.city}, ${addressFromAvs?.state}`
                : '';
            ({ offset } = await fetchTimeZoneAPI(locationavsAddress || address));
        }

        const storesData =
            overridePCList?.length === 0
                ? await getStores(
                      viewCartFromStorage,
                      startDate,
                      endDate,
                      projectDetails,
                      userType,
                      dispatch,
                      assets,
                      howToGetYourOrderDetails,
                      true,
                      isIn24Hrs(startDate, offset)
                  )
                : '';
        // Select the relevant store data based on the overridePCList or storesData
        const selStoreData = overridePC?.pc
            ? overridePC || {}
            : storesData?.data?.data?.[storesData?.deliveryStoreIndex] || {};
        // Extract values from selStoreData
        const currentPC = selStoreData?.pc;
        const pcLat = selStoreData?.latitude;
        const pcLong = selStoreData?.longitude;
        const distance =
            overridePC?.distance ||
            storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.drivingDistanceFromJobsite;
        const specialtyTypes = selStoreData?.specialtyTypes;
        updateGeography(currentPC, pcLat, pcLong, distance, specialtyTypes, '', '');
        if (offset && !addressFromAvs) {
            localLocationObj = {
                location: localLocation.streetAddress,
                lat: latFromAVS || parseFloat(localLocation.lat),
                long: longFromAVS || parseFloat(localLocation.long),
                jobSiteAddr2: localLocation.selectedAddress2,
                jobSiteCity: localLocation.selectedCity,
                jobSiteState: localLocation.selectedState,
                jobSiteZip: localLocation.selectedZip,
                pc: currentPC,
                pcLat: pcLat,
                pcLong: pcLong
            };
            getEstimates(localLocationObj, 0, '', '', false, cart?.availableCartItems);
        } else {
            localLocationObj = {
                location: computeAvsAddressLine1(addressFromAvs) || addressFromAvs?.address1,
                lat: latFromAVS,
                long: longFromAVS,
                jobSiteAddr2: addressFromAvs?.address2,
                jobSiteCity: addressFromAvs?.city,
                jobSiteState: addressFromAvs?.state,
                jobSiteZip: addressFromAvs?.zip,
                pc: currentPC,
                pcLat: pcLat,
                pcLong: pcLong
            };
            if (isNewAddressDifferent(localLocationObj)) {
                filterDispatch({
                    type: SET_LOCATION_DATA,
                    pc: localLocationObj?.pc,
                    location: localLocationObj?.location,
                    jobSiteCity: localLocationObj?.jobSiteCity,
                    jobSiteState: localLocationObj?.jobSiteState,
                    jobSiteZip: localLocationObj?.jobSiteZip,
                    lat: localLocationObj?.lat,
                    long: localLocationObj?.long,
                    jobSiteAddr2: localLocationObj?.jobSiteAddr2,
                    pcLat: localLocationObj?.pcLat,
                    pcLong: localLocationObj?.pcLong
                });
            } else {
                getEstimates(localLocationObj, 0, '', '', false, cart?.availableCartItems);
            }
        }
        if (isPCfulfillmentVoid(startDate, offset, storesData) || !currentPC) {
            setAvsError(intl.formatMessage({ id: 'checkout:delivery-unavailable-error' }));
            setStartDateError(intl.formatMessage({ id: 'checkout:no-delivery-slots-available' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'checkout:no-delivery-slots-available' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.START_DATE
            );
            dispatch({ type: 'endLoading' });
        } else {
            await dateSlotApi(localLocationObj, offset, handleStepChange, currentPC, checkoutErrorHandlingAnalytics);
        }
        if (currentPC) {
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_STORE_DETAILS,
                value: storesData?.data?.data?.[storesData?.deliveryStoreIndex]
            });
        }
        dispatch({ type: 'endLoading' });
        return localLocationObj;
    };

    const assets = getAssets();

    const computeContinueORCreditRoundTrip = async (
        localLocation,
        checkout,
        handleStepChange,
        checkoutErrorHandlingAnalytics
    ) => {
        let localLocationObj = {
            location: '',
            lat: '',
            long: '',
            jobSiteAddr2: '',
            jobSiteCity: '',
            jobSiteState: '',
            jobSiteZip: '',
            pc: '',
            pcLat: '',
            pcLong: ''
        };
        if (checkout && handleStepChange) {
            let overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
            const address = `${localLocation?.streetAddress}, ${localLocation?.selectedCity}, ${localLocation?.selectedState}`;
            const { offset } = await fetchTimeZoneAPI(address);
            const storesData = !overridePC?.pc
                ? await getStores(
                      viewCartFromStorage,
                      startDate,
                      endDate,
                      projectDetails,
                      userType,
                      dispatch,
                      assets,
                      howToGetYourOrderDetails,
                      true,
                      isIn24Hrs(startDate, offset)
                  )
                : '';
            // Select the relevant store data based on the overridePCList or storesData
            const selStoreData = overridePC?.pc
                ? overridePC || {}
                : storesData?.data?.data?.[storesData?.deliveryStoreIndex] || {};
            // Extract values from selStoreData
            const currentPC = selStoreData?.pc;
            const pcLat = selStoreData?.latitude;
            const pcLong = selStoreData?.longitude;
            const distance =
                overridePC?.distance ||
                storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.drivingDistanceFromJobsite;
            const specialtyTypes = selStoreData?.specialtyTypes;

            localLocationObj = {
                location: localLocation.streetAddress,
                lat: localLocation.lat,
                long: localLocation.long,
                jobSiteAddr2: localLocation.selectedAddress2,
                jobSiteCity: localLocation.selectedCity,
                jobSiteState: localLocation.selectedState,
                jobSiteZip: localLocation.selectedZip,
                pc: currentPC,
                pcLat: pcLat,
                pcLong: pcLong,
                distance,
                specialtyTypes
            };
            if (currentPC) {
                dispatch({
                    type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                    key: HOW_TO_GET_YOUR_ORDER.SELECTED_STORE_DETAILS,
                    value: overridePC?.pc ? overridePC : storesData?.data?.data?.[storesData?.deliveryStoreIndex]
                });
            }

            if ((isPCfulfillmentVoid(startDate, offset, storesData) && !overridePC?.pc) || !currentPC) {
                setAvsError(intl.formatMessage({ id: 'checkout:delivery-unavailable-error' }));
                setStartDateError(intl.formatMessage({ id: 'checkout:no-delivery-slots-available' }));
                dispatch({ type: 'endLoading' });
                if (userType === USER_TYPE.CREDIT) {
                    sendEventsForError(
                        VARIABLE_CONFIG.ECOMMERCE_PAGE.CHECKOUT,
                        intl.formatMessage({ id: 'checkout:no-delivery-slots-available' })
                    );
                    checkoutErrorHandlingAnalytics(
                        intl.formatMessage({ id: 'checkout:no-delivery-slots-available' }),
                        VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.START_DATE
                    );
                }
            } else {
                await dateSlotApi(
                    localLocationObj,
                    offset,
                    handleStepChange,
                    currentPC,
                    checkoutErrorHandlingAnalytics
                );
            }
            if (localLocationObj?.pcLat && localLocationObj?.pcLong) {
                updateGeography(
                    localLocationObj?.pc,
                    localLocationObj?.pcLat,
                    localLocationObj?.pcLong,
                    localLocationObj?.distance,
                    localLocationObj?.specialtyTypes,
                    '',
                    ''
                );
            }
            getEstimates(localLocationObj, 0, '', '', false, cart?.availableCartItems);
        }
    };

    return {
        handleRTApis,
        computeContinueORCreditRoundTrip,
        setAvsError,
        setEndDateError,
        setStartDateError,
        fetchTimeZoneAPI,
        dateSlotApi,
        setCanadaInvalidStateError,
        currentOffSet,
        suggestedAddressList,
        avsError,
        startDateError,
        endDateError,
        canadaInvalidStateError,
        isCanadaStateValid,
        getAvsResponse,
        setSuggestedAddressList,
        computeAvsAddressLine1,
        getEquipentsList,
        isLargeEquipment
    };
};

export default useRoundTrip;
