import { array, bool, func, node, oneOf, string, number } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import AlertTriangle from '../../../../resources/images/alert-triangle.svg';
import AlertSuccess from '../../../../resources/images/checkmark-circle.svg';
import CloseButton from '../../../../resources/images/close.svg';
import AlertIcon from '../../../../resources/images/error.svg';
import { ALERT_SUCCESS, ALERT_TRIANGLE } from '../../constants';
import Button from '../button/button';
import './alert.scss';

const Alert = props => {
    const intl = useIntl();
    const {
        type,
        message,
        localStyle,
        dataTestId = '',
        icon,
        id,
        customClass,
        showCloseButton,
        onClose,
        showLink,
        linkLabel,
        linkUrl,
        tabIndex,
        ariaLabel,
        anchorTagName,
        noRole,
        multipleAnchorTagNames,
        scrollMarginTopVal
    } = props;
    const alertType = {
        error: 'alert-error',
        warning: 'alert-warning',
        success: 'alert-success'
    };

    const renderIcon = () => {
        if (typeof icon === 'string') {
            return (
                <>
                    {icon === ALERT_SUCCESS ? (
                        <AlertSuccess tabIndex={'-1'} aria-hidden={true} />
                    ) : icon === ALERT_TRIANGLE ? (
                        <AlertTriangle tabIndex={'-1'} aria-hidden={true} />
                    ) : (
                        <AlertIcon tabIndex={'-1'} aria-hidden={true} />
                    )}
                </>
            );
        } else {
            return icon;
        }
    };

    const anchorTag = () => (
        <>
            {message}{' '}
            <a href="#unavailable-rentals" className="cart-page__viewdetails" tabIndex="0" aria-label={anchorTagName}>
                {' '}
                {anchorTagName}{' '}
            </a>
        </>
    );

    const handleAnchorClick = (event, targetId) => {
        event?.preventDefault();
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            targetElement.parentElement.style.scrollMarginTop = `${scrollMarginTopVal}px`;
            targetElement.parentElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    const renderMultipleAnchorList = () => {
        if (!multipleAnchorTagNames?.length) return null;
        return (
            <ul className="multiple-errors-container">
                {multipleAnchorTagNames?.map(item => (
                    <li key={item?.id}>
                        <a
                            href={`#${item?.id}`}
                            className="multiple-errors-links"
                            onClick={e => handleAnchorClick(e, item?.id)}>
                            {item?.label}
                        </a>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div
            role={noRole ? '' : 'alert'}
            className={`alert ${alertType[type]} ${localStyle} ${customClass}`}
            data-testid={dataTestId}
            tabIndex={tabIndex || '0'}
            aria-label={ariaLabel}
            id={id}>
            {renderIcon()}
            <div className={`${anchorTagName ? '' : 'alert_message_container'}`}>
                {anchorTagName ? anchorTag() : message}
                {showLink && (
                    <a className="alert_link" href={linkUrl} data-testid="wishlistLink" aria-live="assertive" tabIndex="0">
                        {linkLabel}
                    </a>
                )}
            </div>
            {renderMultipleAnchorList()}

            {showCloseButton && (
                <Button
                    type="button"
                    className="button button-pb0 close_icon"
                    customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                    title={'close_button'}
                    tabIndex={0}
                    onClick={onClose}>
                    <CloseButton tabIndex={-1} aria-hidden="true" />
                </Button>
            )}
        </div>
    );
};
Alert.propTypes = {
    type: oneOf(['error', 'warning', 'success']).isRequired,
    message: node,
    localStyle: string,
    id: string,
    role: oneOf(['alert', 'none']),
    showCloseButton: bool,
    showLink: bool,
    linkLabel: string,
    linkUrl: string,
    multipleAnchorTagNames: array,
    scrollMarginTopVal: number
};

Alert.defaultProps = {
    role: 'alert',
    localStyle: '',
    id: '',
    showCloseButton: false,
    showLink: false,
    linkLabel: '',
    linkUrl: '',
    multipleAnchorTagNames: [],
    scrollMarginTopVal: 0
};
export default Alert;
