import { bool, element, func, oneOfType, string } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { SET_CLICKED_MAKE_CHANGES, SET_PAYMENT_TOKEN_DATA } from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../contexts/cart';
import AlertModal from '../../modules/modals/alertModal/AlertModal';
import Button from '../button/button';
import classes from './alertModalWithButtons.css';

export default function AlertModalWithButtons(props) {
    const {
        isOpen,
        title,
        warningQuestion,
        handleContinueClick,
        continueButtonText,
        handleCancelClick,
        cancelButtonText,
        closeIconDataLocator,
        continueButtonDataLocator,
        cancelButtonDataLocator,
        titleDataLocator,
        warningQuestionDataLocator,
        alertTooltipDataLocator,
        isWarning,
        icon,
        role,
        showClose,
        customClass,
        content,
        handleSecondaryBtnClick,
        secondaryButtonText,
        secondaryButtonDataLocator
    } = props;
    const intl = useIntl();
    const [{}, dispatch] = useCartState();
    const onContinue = () => {
        dispatch({ type: SET_CLICKED_MAKE_CHANGES, value: true });
        dispatch({ type: SET_PAYMENT_TOKEN_DATA, value: {} });
        handleContinueClick();
    };

    return (
        <>
            <AlertModal
                isModalWithButtonOpen={isOpen}
                title={title}
                role={role}
                customClass={customClass}
                content={
                    <div className={classes.backToCartRoot}>
                        <div className={classes.alertQuestion} data-testid={warningQuestionDataLocator}>
                            {warningQuestion}
                        </div>
                        <div className={classes.buttonList}>
                            {continueButtonText && (
                                <Button
                                    tabIndex={0}
                                    className={`button button-primary button-block ${
                                        (cancelButtonText || secondaryButtonText) && classes.buttonMarginbottom
                                    }`}
                                    type="button"
                                    buttonAriaLabel={`${continueButtonText}`}
                                    onClick={onContinue}
                                    data-testid={continueButtonDataLocator}>
                                    {continueButtonText}
                                </Button>
                            )}
                            {cancelButtonText && (
                                <Button
                                    tabIndex={0}
                                    className={'button button-outline-primary'}
                                    type="button"
                                    buttonAriaLabel={`${cancelButtonText}`}
                                    onClick={handleCancelClick}
                                    data-testid={cancelButtonDataLocator}>
                                    {cancelButtonText}
                                </Button>
                            )}
                            {secondaryButtonText && (
                                <Button
                                    tabIndex={0}
                                    className={'button button-outline-primary'}
                                    type="button"
                                    buttonAriaLabel={`${secondaryButtonText}`}
                                    onClick={handleSecondaryBtnClick}
                                    data-testid={secondaryButtonDataLocator}>
                                    {secondaryButtonText}
                                </Button>
                            )}
                        </div>
                    </div>
                }
                handleCloseModal={handleCancelClick}
                titleDataLocator={titleDataLocator}
                closeIconDataLocator={closeIconDataLocator}
                alertTooltipDataLocator={alertTooltipDataLocator}
                isWarning={isWarning}
                icon={icon}
                alertClassname={classes.checkoutAlertModal}
                showClose={showClose}
            />
        </>
    );
}

AlertModalWithButtons.propsType = {
    isOpen: bool,
    title: string,
    warningQuestion: oneOfType([string, element]),
    continueButtonText: string,
    cancelButtonText: string,
    closeIconDataLocator: string,
    continueButtonDataLocator: string,
    cancelButtonDataLocator: string,
    titleDataLocator: string,
    warningQuestionDataLocator: string,
    alertTooltipDataLocator: string,
    handleContinueClick: func,
    handleCancelClick: func,
    showClose: bool,
    customClass: string,
    handleSecondaryBtnClick: func,
    secondaryButtonText: string,
    secondaryButtonDataLocator: string
};

AlertModalWithButtons.defaultProps = {
    isOpen: false,
    title: '',
    warningQuestion: '',
    continueButtonText: '',
    cancelButtonText: '',
    closeIconDataLocator: '',
    continueButtonDataLocator: '',
    cancelButtonDataLocator: '',
    titleDataLocator: '',
    warningQuestionDataLocator: '',
    alertTooltipDataLocator: '',
    handleContinueClick: () => {},
    handleCancelClick: () => {},
    isWarning: false,
    showClose: false,
    customClass: '',
    handleSecondaryBtnClick: () => {},
    secondaryButtonText: '',
    secondaryButtonDataLocator: ''
};
