import React, { memo } from 'react';
import { node, string } from 'prop-types';
import './viewCartFooter.scss';

const ViewCartFooter = ({ children, footerClass }) => {
    return <div className={`viewcart-footer ${footerClass}`}>{children}</div>;
};

ViewCartFooter.propTypes = {
    children: node,
    footerClass: string
};

ViewCartFooter.defaultProps = {
    footerClass: '',
    children: <></>
};

export default memo(ViewCartFooter);
