import isObjectEmpty from '../../../../aem-core-components/utils/isObjectEmpty';

var otherLocationOne = document.querySelector('.other__location-one');
var otherLocationTwo = document.querySelector('.other__location-two');
var otherLocationThree = document.querySelector('.other__location-three');
var selectedStore = localStorage.getItem('selectedStoreDetails')
    ? JSON.parse(localStorage.getItem('selectedStoreDetails'))
    : {};
var addressCompanyId = parseInt(localStorage.getItem('companyID')) || 1;
var locationBranchDistanceDetail = document.querySelector('.location__branch-distance');
var blockOtherLocation = document.querySelector('.block--other-location');

if (window.location.href.indexOf('/location') > -1 && selectedStore) {
    if (selectedStore?.distance) {
        var getLocationDetail =
            Math.floor(addressCompanyId) === 2
                ? `${Math.round(selectedStore.distance)} km`
                : `${Math.round(selectedStore.distance)} mi`;
        if (locationBranchDistanceDetail) {
            locationBranchDistanceDetail.innerHTML = getLocationDetail;
        }
    }
    var getNearStore = (key, value) => {
        if (localStorage.getItem(key)) {
            const storedData = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : {};
            const item = storedData ? storedData?.[value] : {};
            var {
                name = '',
                phone = '',
                distance = 0,
                companyId = 0,
                hours = [],
                street = '',
                zip = '',
                state = '',
                city = '',
                pc = '',
                specialtyUrl = '',
                branchText = ''
            } = item || {};
            return otherLocationDiv(
                name,
                phone,
                distance,
                companyId,
                hours,
                street,
                zip,
                state,
                city,
                pc,
                addressCompanyId,
                specialtyUrl,
                branchText
            );
        }
        return '';
    };
    var convertPhone = phoneNo => {
        if (phoneNo) {
            return phoneNo?.substr(1, 3) + '-' + phoneNo?.substr(6, 8);
        }
        return '';
    };

    var formatUrl = text => {
        if (text) {
            let formattedText = text?.replace(/\s+/g, '-');
            formattedText = formattedText?.toLowerCase();
            return formattedText;
        }
        return '';
    };
    var checkSpecialtiesTitle = (pc, name) => {
        if (pc >= 5000 && pc <= 5099) {
            return 'Sunbelt Rentals Tool Rental';
        } else return name;
    };

    var otherLocationDiv = (
        name,
        phone,
        distance,
        companyId,
        hours,
        street,
        zip,
        state,
        city,
        pc,
        addressCompanyId,
        specialtyUrl,
        branchText
    ) => {
        var currentLocation = companyId === 2 ? '/ca' : '';
        return `<div class="other__location-wrap">
      <div class="other__location-top">
        <div class="other__location-title" data-testid="locationDetailsPage_otherStoreDetails_storeName">${checkSpecialtiesTitle(
            pc,
            name
        )}</div>
        <div class="other__location-distance" data-testid="locationDetailsPage_otherStoreDetails_distance">
        ${
            distance ? (parseInt(addressCompanyId) == 2 ? `${parseInt(distance)} km` : `${parseInt(distance)} mi`) : ''
        }     
        </div>
      </div>
      <small class="other_location-branchid">${branchText}</small>
      <div class="other__location-hours" data-testid="locationDetailsPage_otherStoreDetails_staticText">${hours}</div>
      <div class="other__location-address">
        <button class="other__location-addresslink" role="link" 
            data-redirect="${street || ''},${city || ''},${state || ''}, ${zip || ''}" 
            data-testid="locationDetailsPage_otherStoreDetails_getDirectionsCTA" 
            aria-label="${street || ''},${city || ''},${state || ''}, ${zip || ''} google map (opens in a new window)"
        >
        <i class="icon icon-external-link-green"></i>
        </button>
        <address data-testid="locationDetailsPage_otherStoreDetails_storeAddress">
            <span>${street?.toLowerCase() || ''}</span>
            <span>${city?.toLowerCase() || ''}, ${state || ''} ${zip || ''}</span>
        </address>
      </div>
        <div class="">
            <div class="other__location-actions">
            <a  class="other__location-link other__location-phone" 
                href="tel:${convertPhone(phone)}" 
                data-testid="locationDetailsPage_otherStoreDetails_storeContactNumber" 
                aria-label="call us at ${convertPhone(phone)}"
            >
                ${convertPhone(phone)}
            </a>
            <a href="/location${currentLocation}/${formatUrl(state)}/${formatUrl(city)}/${specialtyUrl}/${pc}/" 
            class="other__location-link other__location-redirect" 
            data-testid="locationDetailsPage_otherStoreDetails_storeDetailsCTA" aria-label="store details of ${branchText}">Store details</a>
                </div>
            <div></div>
            </div>
        </div></div>`;
    };

    if (otherLocationOne) {
        otherLocationOne.innerHTML = getNearStore('nearbyStores', 'closestStore')?.trim();
    }
    if (otherLocationTwo) {
        otherLocationTwo.innerHTML = getNearStore('nearbyStores', 'secondClosest')?.trim();
    }
    if (otherLocationThree) {
        otherLocationThree.innerHTML = getNearStore('nearbyStores', 'thirdClosest')?.trim();
    }
    var otherLocationRedirect = document.querySelectorAll('.other__location-redirect');

    otherLocationRedirect.forEach(item =>
        item?.addEventListener('click', function (e) {
            e.preventDefault();
            const nearbyStores = localStorage.getItem('nearbyStores')
                ? JSON.parse(localStorage.getItem('nearbyStores'))
                : {};
            const { closestStore, secondClosest, thirdClosest } = nearbyStores;
            let setLocationData = { closestStore, secondClosest, thirdClosest };

            let newSelectdStore = {};
            if (e.currentTarget.closest('.other__location-in').classList.contains('other__location-one')) {
                newSelectdStore = closestStore;
                setLocationData.closestStore = selectedStore;
                setLocationData.secondClosest = secondClosest;
                setLocationData.thirdClosest = thirdClosest;
            } else if (e.currentTarget.closest('.other__location-in').classList.contains('other__location-two')) {
                newSelectdStore = secondClosest;
                setLocationData.closestStore = closestStore;
                setLocationData.secondClosest = selectedStore;
                setLocationData.thirdClosest = thirdClosest;
            } else {
                newSelectdStore = thirdClosest;
                setLocationData.closestStore = secondClosest;
                setLocationData.secondClosest = closestStore;
                setLocationData.thirdClosest = selectedStore;
            }
            localStorage.setItem('selectedStoreDetails', JSON.stringify(newSelectdStore));
            localStorage.setItem('nearbyStores', JSON.stringify(setLocationData));
            window.location.href = e.currentTarget.href;
        })
    );

    var addresslinks = document.querySelectorAll('.other__location-addresslink');
    addresslinks.forEach(function (link) {
        link?.addEventListener('click', function (e) {
            var getSetLocation = localStorage.getItem('startingLocationForGetDirections') || '';
            var newGetSetLocation = getSetLocation.replace(/[^A-Z0-9]+/gi, ' ');
            var newLink = `https://www.google.com/maps/dir/${newGetSetLocation}/${e.currentTarget.dataset.redirect}`;
            window.open(newLink, '_blank');
        });
    });

    const nearbyStores = localStorage.getItem('nearbyStores') ? JSON.parse(localStorage.getItem('nearbyStores')) : {};
    const { closestStore, secondClosest } = nearbyStores;
    if (isObjectEmpty(secondClosest) || isObjectEmpty(closestStore)) {
        if (blockOtherLocation) {
            blockOtherLocation.classList.add('block--other-locationhide');
        }
    }
}
