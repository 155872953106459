export const SET_CART_OVERLAY = 'SET_CART_OVERLAY';
export const POP_CART_STACK = 'POP_CART_STACK';
export const SET_VIEW_CART_FIELDS = 'SET_VIEW_CART_FIELDS';
export const SET_HOW_TO_GET_YOUR_ORDER_FIELDS = 'SET_HOW_TO_GET_YOUR_ORDER_FIELDS';
export const HTGO_EMPTY_DELIVERY_PICKUP_TIME = 'HTGO_EMPTY_DELIVERY_PICKUP_TIME';
export const RESET_ORDER_ESTIMATES = 'RESET_ORDER_ESTIMATES';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const RESET_PROJECT = 'RESET_PROJECT';
export const SET_ANIMATED_HEADER = 'SET_ANIMATED_HEADER';
export const SET_MODAL = 'SET_MODAL';
export const UPDATE_VIEW_CART = 'UPDATE_VIEW_CART';
export const UPDATE_PROJECT_DETAILS = 'UPDATE_PROJECT_DETAILS';
export const SET_CREDIT_NEWADDR_FLAG = 'SET_CREDIT_NEWADDR_FLAG';
export const SET_START_DATE_SLOTS = 'SET_START_DATE_SLOTS';
export const SET_END_DATE_SLOTS = 'SET_END_DATE_SLOTS';
export const SET_LOCATION_DATA = 'SET_LOCATION_DATA';
export const RESET_GEOGRAPHY = 'RESET_GEOGRAPHY';
export const RESET_LOCATION_DATA = 'RESET_LOCATION_DATA';
export const SET_GUEST_USER_DETAILS = 'SET_GUEST_USER_DETAILS';
export const SET_CONSUMABLES = 'SET_CONSUMABLES';
export const RESET_CONSUMABLES = 'RESET_CONSUMABLES';
export const SET_CONSUMABLES_SELECTED_ITEMS = 'SET_CONSUMABLES_SELECTED_ITEMS';
export const UPDATE_CONSUMABLES = 'UPDATE_CONSUMABLES';
export const SET_SELECTED_RENTAL_EQUIPMENT = 'SET_SELECTED_RENTAL_EQUIPMENT';
export const SET_SHOW_RENTAL_EQUIPMENT_SCREEN = 'SET_SHOW_RENTAL_EQUIPMENT_SCREEN';
export const RESET_YOUR_DETAILS = 'RESET_YOUR_DETAILS';
export const RESET_HTGO = 'RESET_HTGO';
export const RESET_HTGO_FROM_YOUR_DETAILS = 'RESET_HTGO_FROM_YOUR_DETAILS';
export const RESET_ACCESSORIES_AND_ADDONS = 'RESET_ACCESSORIES_AND_ADDONS';
export const RESET_PAYMENT = 'RESET_PAYMENT';
export const SET_PAYMENT = 'SET_PAYMENT';
export const SET_SELECTED_PAYMENT_METHOD = 'SET_SELECTED_PAYMENT_METHOD';
export const SET_PAYMENT_SESSION = 'SET_PAYMENT_SESSION';
export const RESET_PROJECT_DETAILS = 'PROJECT_DETAILS';
export const EDIT_PROJECT_INFO = 'EDIT_PROJECT_INFO';
export const SET_OPTIONAL_PLANS_CHECKED = 'SET_OPTIONAL_PLANS_CHECKED';
export const SET_SUBMIT_RESERVATION_VISIBILITY = 'SET_SUBMIT_RESERVATION_VISIBILITY';
export const SET_SUBMIT_RESERVATION_TYPE = 'SET_SUBMIT_RESERVATION_TYPE';
export const SET_CURRENT_ACCESSORIES_AVAILABLE_QUANTITY = 'SET_CURRENT_ACCESSORIES_AVAILABLE_QUANTITY';
export const SET_CURRENT_ADDONS_AVAILABLE_QUANTITY = 'SET_CURRENT_ADDONS_AVAILABLE_QUANTITY ';
export const SET_SAVED_AVAILABLE_QUANTITY = 'SET_SAVED_AVAILABLE_QUANTITY';
export const SET_CURRENT_OFFSET_VALUE = 'SET_CURRENT_OFFSET_VALUE';
export const SET_SUBMIT_RESERVATION = 'SET_SUBMIT_RESERVATION';
export const SET_TIMEZONE_ID = 'SET_TIMEZONE_ID';
export const SET_HOME_ASSETS_RATES = 'SET_HOME_ASSETS_RATES';
export const SET_STORE_LOCATIONS = 'SET_STORE_LOCATIONS';
export const SET_YOUR_DETAILS = 'SET_YOUR_DETAILS';
export const UPDATE_PUNCHOUT_USER_DETAILS = 'UPDATE_PUNCHOUT_USER_DETAILS';
export const SET_CART_CONTEXT_FIELDS = 'SET_CART_CONTEXT_FIELDS';
export const CREDIT_CUSTOM_ACCOUNT_INFO = 'CREDIT_CUSTOM_ACCOUNT_INFO';
export const SET_PUNCHOUT_USER_DATA = 'SET_PUNCHOUT_USER_DATA';
export const SET_OPTIONAL_PLANS_CHECKBOX_VISIBLE = 'SET_OPTIONAL_PLANS_CHECKBOX_VISIBLE';
export const SET_RATES_LOADING_FLAG = 'SET_RATES_LOADING_FLAG';
export const SET_OPTIONAL_PLANS_IS_EDIT = 'SET_OPTIONAL_PLANS_IS_EDIT';
export const SET_PAYMENT_TOKEN_DATA = 'SET_PAYMENT_TOKEN_DATA';
export const SET_CLICKED_MAKE_CHANGES = 'SET_CLICKED_MAKE_CHANGES';
export const SET_SOURCES_LOADING_FLAG = 'SET_SOURCES_LOADING_FLAG';
export const SET_CART_ITEMS = 'SET_CART_ITEMS';
export const SET_CASH_PROFILE_UPDATED = 'SET_CASH_PROFILE_UPDATED';
export const RESET_EDIT_ON_CHECKOUT_COMPLETE = 'RESET_EDIT_ON_CHECKOUT_COMPLETE';
export const SET_ORDER_ESTIMATES_FINAL_TOTAL = 'SET_ORDER_ESTIMATES_FINAL_TOTAL';
export const SET_SHOW_DELINQUENT_ACCOUNT_MODAL = 'SET_SHOW_DELINQUENT_ACCOUNT_MODAL';
export const SET_IS_RESERVATION_CLICKED = 'SET_IS_RESERVATION_CLICKED';
export const SET_SELECTED_COMBINED_TAB_DATA = 'SET_SELECTED_COMBINED_TAB_DATA';
export const SET_IS_COMBINED_TAB_LOADING = 'SET_IS_COMBINED_TAB_LOADING';
export const SET_START_CLICK = 'SET_START_CLICK';
export const SET_END_CLICK = 'SET_END_CLICK';
export const RESET_CLICKS = 'RESET_CLICKS';
export const SET_CALENDAR_DATE_INTERACTION = 'SET_CALENDAR_DATE_INTERACTION';
export const SET_ESTIMATES_IN_CART = 'SET_ESTIMATES_IN_CART';
export const SET_CID_PC_LIST = 'SET_CID_PC_LIST';
export const SET_ATP_PC_LIST = 'SET_ATP_PC_LIST';
export const SET_IS_ATC_MODAL_OPEN = 'SET_IS_ATC_MODAL_OPEN';
export const SET_IS_CART_LOADING = 'SET_IS_CART_LOADING';
export const SET_IS_COMBINED_TABS_LOADING = 'SET_IS_COMBINED_TABS_LOADING';
export const SET_REDIRECT_LINK = 'SET_REDIRECT_LINK';
export const SHOW_ACCOUNT_OVERLAY = 'SHOW_ACCOUNT_OVERLAY';
export const RESET_PRICES = 'RESET_PRICES';
export const SET_IS_BULK_ATC_MODAL_OPEN = 'SET_IS_BULK_ATC_MODAL_OPEN';
export const SET_SELECTED_TRANSMIT_OR_SAVE_FOR_LATER = 'SET_SELECTED_TRANSMIT_OR_SAVE_FOR_LATER';
export const SET_ORDER_SUMMARY_DETAILS = 'SET_ORDER_SUMMARY_DETAILS';
export const SET_UPDATED_EQUIPMENT = 'SET_UPDATED_EQUIPMENT';
export const SET_WARNING_VALUE = 100;
export const SET_INFO_VALUE = 75;
export const SET_ZERO_VALUE = 0;
export const SET_SHOW_UNAVAILABLE_ITEMS = 'SET_SHOW_UNAVAILABLE_ITEMS';
export const SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS = 'SET_SHOW_NO_RESULTS_FOR_UNAVILABLE_ITEMS';
export const SET_STATIC_TILE_PRODUCT_DETAILS = 'SET_STATIC_TILE_PRODUCT_DETAILS';
export const GET_DEDICATED_PC = 'GET_DEDICATED_PC';
export const SET_IS_USER_SIGNING_IN = 'SET_IS_USER_SIGNING_IN';
export const SET_SHOW_SIGNIN_MODAL = 'SET_SHOW_SIGNIN_MODAL';
export const SET_SHOW_CHOOSE_ACCOUNT_DRAWER = 'SET_SHOW_CHOOSE_ACCOUNT_DRAWER';
export const SET_SHOW_JOB_SITE_DRAWER = 'SET_SHOW_JOB_SITE_DRAWER';
export const SET_CAP_ACCOUNT_SELECTED = 'SET_CAP_ACCOUNT_SELECTED';
export const SET_LAST_USED_JOBSITE = 'SET_LAST_USED_JOBSITE';
export const SET_CART_AVAILABLE_UNVAILABLE_ITEMS = 'SET_CART_AVAILABLE_UNVAILABLE_ITEMS';
export const SET_IS_JOBSITE_LOADED = 'SET_IS_JOBSITE_LOADED';
export const SET_LOCATION_LOADING = 'SET_LOCATION_LOADING';
export const SET_IS_ITEM_PC_ADDED = 'SET_IS_ITEM_PC_ADDED';
export const SET_CHECKOUT_ERROR_DETAILS = 'SET_CHECKOUT_ERROR_DETAILS';
export const SET_RECOMPUTE_ITEM_AVAILABILITY = 'SET_RECOMPUTE_ITEM_AVAILABILITY';
export const SET_PICKUP_TIME_SLOTS = 'SET_PICKUP_TIME_SLOTS';
export const SET_RETURN_TIME_SLOTS = 'SET_RETURN_TIME_SLOTS';
export const SET_ACCOUNT_FAVORITE_LIST_DATA = 'SET_ACCOUNT_FAVORITE_LIST_DATA';
export const SET_JOBSITE_FAVORITE_LIST_DATA = 'SET_JOBSITE_FAVORITE_LIST_DATA';
export const SET_ALL_ACCOUNT_PROJECTS = 'SET_ALL_ACCOUNT_PROJECTS';
export const SET_IS_FAVORITES_LOADING = 'SET_IS_FAVORITES_LOADING';
export const SET_SHOW_AVS_ERROR_IN_CAP = 'SET_SHOW_AVS_ERROR_IN_CAP';
export const SET_LOADERS_FIELD = 'SET_LOADERS_FIELD';
