import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';
import { App as CommerceApp, ConfigContextProvider, Portal } from '../../aem-core-components';
import MultiPortal from '../../aem-core-components/components/Portal/MultiPortal';
import Wires from '../global/atoms/wires/Wires';
import RentalsCombineTab from '../global/modules/rentalsCombineTab/RentalsCombineTab';
import { initSentry } from '../global/utils/logger';
import ContactUsMarketo from '../marketoforms/contactUs';
import AddToCartProductTile from '../global/modules/productTile/addToCartProductTile';
import Punchout from '../punchout/Punchout';
import PopularRentals from '../search/popularrentals/PopularRentals';
import LdpAlert from '../ldpAlert/LdpAlert';
import { CapHeader, CapSignIn, CapBottomSticky } from '../cap';
import partialConfig from './config';
import Global from './Global';
import HeroSearch from '../search/heroSearch';
import DataBricks from '../global/modules/dataBricks/DataBricks';
//const Global = React.lazy(() => import(/* webpackMode: "eager" */'./Global'));
import loadLocaleData, { locale, messages } from './i18n';

const App = props => {
    const {
        storeView,
        graphqlEndpoint,
        graphqlMethod = 'POST',
        headers = '{}'
    } = JSON.parse(
        document.querySelector('meta[name="store-config"]')?.content ||
            document.querySelector('div[name="store-config"]')?.getAttribute('content') ||
            '{}'
    );
    const { mountingPoints, pagePaths } = partialConfig;
    const config = {
        ...partialConfig,
        storeView,
        graphqlEndpoint,
        // Can be GET or POST. When selecting GET, this applies to cache-able GraphQL query requests only. Mutations
        // will always be executed as POST requests.
        graphqlMethod,
        headers
    };
    return (
        <IntlProvider locale={locale} messages={messages}>
            <ConfigContextProvider config={config}>
                <CommerceApp>
                    <Global />
                    <Portal selector={mountingPoints.heroSearchBox}>
                        <HeroSearch />
                    </Portal>
                    <Portal selector={mountingPoints.contactUsMarketo}>
                        <ContactUsMarketo />
                    </Portal>
                    <MultiPortal selector={mountingPoints.addToCartTile}>
                        <AddToCartProductTile />
                    </MultiPortal>
                    <Portal selector={mountingPoints.combinedTabs}>
                        <RentalsCombineTab />
                    </Portal>
                    <Portal selector={mountingPoints.punchoutUserSection}>
                        <Punchout />
                    </Portal>
                    <Portal selector={mountingPoints.popularRentals}>
                        <PopularRentals />
                    </Portal>
                    <Portal selector={mountingPoints.wiresCrossed}>
                        <Wires />
                    </Portal>
                    <Portal selector={mountingPoints.capSideHeader}>
                        <CapSignIn />
                    </Portal>
                    <Portal selector={mountingPoints.capHeader}>
                        <CapHeader />
                    </Portal>
                    <Portal selector={mountingPoints.capHeaderBottomSticky}>
                        <CapBottomSticky />
                    </Portal>
                    <Portal selector={mountingPoints.ldpAlertMessage}>
                        <LdpAlert />
                    </Portal>
                    <Portal selector={mountingPoints.recommendationCarousal}>
                        <DataBricks />
                    </Portal>
                </CommerceApp>
            </ConfigContextProvider>
        </IntlProvider>
    );
};
const renderDOM = async () => {
    const { locale, messages } = await loadLocaleData();
    const root = document.createElement('div');
    document.body.appendChild(root);
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (!isDevelopment) {
        initSentry();
    }
    const rootContainer = createRoot(root);
    rootContainer.render(
        <Router>
            <App locale={locale} messages={messages} />
        </Router>
    );
};

document?.addEventListener('readystatechange', () => {
    if (document?.readyState === 'complete') {
        renderDOM();
    }
});

export default App;
